import React, { Fragment } from 'react'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Dialog, Transition } from '@headlessui/react'
import classNames from 'classnames'

import { Button, Icon, Text } from 'src/components/ui'
import { zIndex } from 'src/utility/constants/StyleConstants'

type ModalProps = {
  title?: string
  footer?: JSX.Element
  isOpen: boolean
  close: () => void
  children?: React.ReactElement
  customStyles?: string
  contentClassName?: string
  closeIconSize?: 'small' | 'regular' | 'big'
  allowOverflow?: boolean
}

export function Modal({
  title,
  footer,
  isOpen,
  close,
  children,
  customStyles,
  contentClassName,
  closeIconSize = 'big',
  allowOverflow,
}: ModalProps): JSX.Element {
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative min-h-full bg-black"
          style={{ zIndex: zIndex.modal }}
          onClose={close}
        >
          <div className="fixed inset-0 overflow-y-auto bg-[#000]/70">
            <div className="flex max-h-screen min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-200"
                enterFrom="opacity-0 scale-95"
                enterTo="opacimodalty-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className={classNames(
                    'max-h-[calc(100%-2rem)] min-w-[400px] rounded-2xs bg-white p-m text-left align-middle shadow-xl transition-all',
                    !allowOverflow && 'overflow-hidden',
                    customStyles,
                  )}
                >
                  <div
                    className={classNames(
                      'flex max-h-full flex-col',
                      contentClassName,
                    )}
                  >
                    {title && (
                      <div className="flex w-full items-center justify-between">
                        <Text variant="title" bold>
                          {title}
                        </Text>
                        <div
                          onClick={close}
                          className="-translate-y-s translate-x-s cursor-pointer"
                        >
                          <Icon
                            icon={regular('circle-xmark')}
                            size={closeIconSize}
                          />
                        </div>
                      </div>
                    )}
                    {children || null}
                    {footer && (
                      <div className="border border-solid border-grey-disabled" />
                    )}
                    {footer}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

type FooterProps = {
  onCancel: () => any
  onConfirm: () => any
  cancelLabel?: string
  confirmLabel: string
  type: 'success' | 'danger'
  buttonDisabled: boolean
}

const Footer = ({
  onCancel,
  onConfirm,
  cancelLabel,
  confirmLabel,
  type,
  buttonDisabled,
}: FooterProps): JSX.Element => (
  <div className="mt-s flex w-full items-center justify-end gap-s">
    <button
      onClick={onCancel}
      type="button"
      className="cursor-pointer border-0 bg-transparent text-content font-500 leading-description"
    >
      {cancelLabel || 'Cancel'}
    </button>
    <Button
      variant={'primary'}
      onClick={onConfirm}
      disabled={buttonDisabled}
      title={confirmLabel}
      className={`${
        type === 'danger' &&
        '!bg-delete-primary hover:!bg-delete-hover active:!bg-delete-hover'
      }`}
    />
  </div>
)

Modal.Footer = Footer
