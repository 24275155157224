import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { useState } from 'react'
import toast from 'react-hot-toast'
import {
  useUpdateConsentMutation,
  useUpdateDefaultFactoryMutation,
} from 'account/api'
import { Icon, SelectInput, Text } from 'src/components/ui'
// TODO: restructure
import { useAuth } from 'src/contexts/auth'

export function AccountPage(): JSX.Element {
  const updateConsent = useUpdateConsentMutation()
  const { viewer } = useAuth()
  const [consent, setConsent] = useState(viewer.dataCollectionConsent)
  const updateDefaultFactory = useUpdateDefaultFactoryMutation()

  async function handleConsentChange(checked: boolean): Promise<void> {
    await updateConsent.mutateAsync(
      {
        cookieConsent: viewer.cookieConsent,
        dataCollectionConsent: checked,
      },
      {
        onSuccess: () => {
          toast.success('Consent Updated')
        },
      },
    )
    setConsent(checked)
  }

  async function handleDefaultFactoryChange(value: string): Promise<void> {
    if (viewer.defaultFactoryId === value) return
    const newFactory = viewer.factories.find(factory => factory.id === value)
    await updateDefaultFactory.mutateAsync(
      {
        factoryId: value,
      },
      {
        onSuccess: () => {
          toast.success(
            `Default Site changed to ${newFactory?.customer.name} | ${newFactory?.name}`,
          )
        },
        onError: () => {
          toast.error('Update failed: Default site unchanged')
        },
      },
    )
  }

  const factoryOptions = viewer.factories.map(factory => ({
    label: `${factory.customer.name} | ${factory.name}`,
    value: factory.id,
  }))

  const currentDefaultFactory = factoryOptions.find(
    factory => factory.value === viewer.defaultFactoryId,
  )

  return (
    <div className="m-s mt-0 h-[calc(100%-1em-50px)] rounded-2xs bg-white p-m">
      <div className="mb-s flex w-full flex-col justify-between gap-l p-s md:flex-row md:items-start">
        <div className="flex items-center gap-l">
          <Icon
            icon={light('user')}
            className="h-3xl w-3xl text-grey-disabled"
          />
          <div className="flex flex-col gap-m">
            <div className="flex flex-col gap-xs">
              <Text variant="content" bold>
                Name
              </Text>
              <Text variant="description">{viewer.name}</Text>
            </div>
            <div className="flex flex-col gap-xs">
              <Text variant="content" bold>
                Email
              </Text>
              <Text variant="description">{viewer.email}</Text>
            </div>
            <div className="flex max-w-[250px] flex-col gap-xs">
              <Text variant="content" bold>
                Default Site
              </Text>
              <SelectInput
                className={classNames(
                  updateDefaultFactory.isLoading &&
                    'opacity-50 pointer-events-none',
                )}
                textVariant="description"
                bold
                options={factoryOptions}
                value={currentDefaultFactory}
                onChange={handleDefaultFactoryChange}
              />
              <Text variant="description">
                Select a site from the drop-down above, to see it first every
                time you login.
              </Text>
            </div>
          </div>
        </div>
        <div className="w-full md:w-[60%]">
          <div className="max-w-[800px]">
            <Text variant="content" bold className="mb-xs">
              Consent Form
            </Text>
            <Text variant="description" className="mb-xs">
              In order to help Intelecy build{' '}
              <span className="font-medium">
                a better and more user-friendly product
              </span>{' '}
              we also <span className="font-medium">ask for consent</span> to
              log user behaviour and more detailed metrics. Only{' '}
              <span className="font-medium">
                data related to the usage of the Intelecy product
              </span>{' '}
              is being collected. This consent gives Intelecy the permission to
              collect and use non-anonymized user behavior data in order to
              improve the product. We collect data on how the{' '}
              <span className="font-medium">user navigates the site</span>,
              which actions they take and when, and their browser version and IP
              address. This data is saved along with the user's full name and
              email address.
            </Text>
            <div className="mt-s flex">
              <div
                className={`flex h-l items-center rounded-2xs px-xs ring-1 ring-grey-disabled ${
                  consent && 'ring-green-primary'
                }`}
              >
                <input
                  type="checkbox"
                  id="consent"
                  name="consent"
                  onChange={event => handleConsentChange(event.target.checked)}
                  checked={consent}
                  className="text-xs"
                />
                <label htmlFor="consent" className="pl-3 text-sm font-medium">
                  <Text variant="description" bold className="my-xs">
                    I consent to share my user behaviour and detail metrics
                  </Text>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
