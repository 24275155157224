import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import * as api from 'src/services'
import { OrganizationRole, organizationRoleToGql } from 'src/types'
import { ORGANIZATION_QUERY } from './org.get'

export function useDeactivateOrganizationUserMutation(): UseMutationResult<
  api.GqlRemoveCustomerUserMutation,
  unknown,
  api.GqlDisableUserInput
> {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (input: api.GqlDisableUserInput) =>
      api.disableUser(input),
    onSuccess: () => {
      // we need to invalidate ORGANIZATION_QUERY for all orgs
      // since we don't know which orgs the user had
      // access to
      queryClient.invalidateQueries({
        queryKey: [ORGANIZATION_QUERY],
      })
    },
  })
}

interface RemoveCustomerUserInput {
  orgId: string
  userId: string
}

export function useRemoveOrganizationUserMutation(): UseMutationResult<
  api.GqlRemoveCustomerUserMutation,
  unknown,
  RemoveCustomerUserInput
> {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ orgId, userId }: RemoveCustomerUserInput) =>
      api.removeOrganizationUser({
        customerId: orgId,
        userId,
      }),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [ORGANIZATION_QUERY, variables.orgId],
      })
    },
  })
}

interface SetOrganizationRoleInput {
  orgId: string
  userId: string
  role: OrganizationRole
}

export function useSetOrganizationRoleMutation(): UseMutationResult<
  api.GqlSetCustomerUserRoleMutation,
  unknown,
  SetOrganizationRoleInput
> {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async ({ orgId, userId, role }: SetOrganizationRoleInput) =>
      api.setCustomerUserRole({
        customerId: orgId,
        userId,
        role: organizationRoleToGql(role),
      }),
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries({
        queryKey: [ORGANIZATION_QUERY, variables.orgId],
      })
    },
  })
}
