import { useState } from 'react'
import { OnlyModelProps } from 'src/types'
import { TimeSeriesData } from 'src/types'
import { Legend } from 'pages/site/models/model'
import { useHistoricalForecast } from 'models/api'
import { useTagData } from 'tags/api'
import { useFilter } from 'src/contexts/filter'
import {
  Icon,
  SelectInput,
  Text,
  Card,
  TimePickerNavigation,
  TimeSeriesChart,
} from 'src/components/ui'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { theme } from 'tailwind.config'
import { GqlFetchModelFragment } from 'src/services'

const getOffsets = (model: GqlFetchModelFragment): number[] => {
  switch (model?.method?.name) {
    case 'Forecast 1H':
      return [15, 30, 45, 60]
    case 'Forecast 2H':
      return [30, 60, 90, 120]
    case 'Forecast 4H':
      return [60, 120, 180, 240]
    default:
      return []
  }
}

export function OutputTagAndHistoricalForecast({
  model,
}: OnlyModelProps): JSX.Element {
  const offsets = getOffsets(model)
  const [offset, setOffset] = useState(offsets[offsets.length - 1])
  const { filter } = useFilter()
  const historicalForecast = useHistoricalForecast({ id: model.id, offset })
  const tagData = useTagData({ id: model.tag.tagName })

  const legend = {
    'Actual value': '#726ADF',
    [`Historical forecast (offset ${offset} min)`]: theme.colors.prediction,
  }
  if (filter) {
    legend['Filtered out'] = theme.colors.filter
  }

  return (
    <Card className="col-span-2">
      <Text variant="title" bold>
        Output tag and historical forecast
      </Text>
      <div className="mb-xs">
        <TimePickerNavigation />
      </div>
      <div className="my-xs ml-[40px] flex items-center justify-between gap-[0.5em]">
        <span>
          <span title={model.tag.tagName}>
            {model.tag.displayName || model.tag.tagName}
          </span>
          {model.tag.description && (
            <span>, description: {model.tag.description}</span>
          )}
          {model.tag.engUnit && <span>, unit: {model.tag.engUnit}</span>}
        </span>
        <div className="flex items-center justify-center gap-xs">
          <Legend data={legend} />
          <SelectInput
            triggerComponent={<Icon icon={solid('cog')} />}
            options={[
              ...offsets
                .filter(o => offset !== o)
                .map(offset => ({
                  label: `Set offset to ${offset} min`,
                  value: offset.toString(),
                })),
            ]}
            onChange={value => setOffset(parseInt(value))}
          />
        </div>
      </div>
      <TimeSeriesChart
        tooltip
        isPending={historicalForecast.isLoading || tagData.isLoading}
        data={[
          {
            name: 'Actual value',
            data: tagData.data,
            color: '#726ADF',
          },
          {
            name: 'Historical forecast',
            data: historicalForecast.data?.map(([ts, avg]: TimeSeriesData) => [
              ts,
              avg,
            ]),
            color: theme.colors.prediction,
          },
        ]}
      />
    </Card>
  )
}
