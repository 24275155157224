import { GqlModelTagBaseFragment, TagDto } from 'src/services'
import { ModelTypes } from 'src/types'

export const getInputTagWarnings = (
  tag: TagDto,
  modelType?: ModelTypes | null,
  outputTagName?: string | null,
): string[] => {
  const warnings = []
  const label = tag.label ?? 'Unlabeled'
  switch (label) {
    case 'Few Values':
      warnings.push(
        'This tag has too few values and may have little or no impact on the model.',
      )
      break
    case 'Unlabeled':
      warnings.push(
        'This tag needs to be analyzed and labeled before use. Labeling tags is currently a process that has to be started manually, so contact Intelecy if you want to use this tag.',
      )
      break
    default:
      break
  }

  if (tag.isBad) {
    warnings.push(
      'More than 10% of the values are considered unreliable due to problems with sensor communication, resulting in bad quality data.',
    )
  }

  if (modelType === 'AnomalyModel' && outputTagName === tag.tagName) {
    warnings.push(
      'Anomaly models of type nowcast do not allow using the output as input. To fix this issue, either remove the output from the list of inputs or change the model type once the model has been created.',
    )
  }

  return warnings
}

export function getOutputTagWarnings(
  tag?: TagDto,
  modelType?: ModelTypes | null,
): string[] {
  if (!tag) return []
  return getOutputWarnings(modelType, tag.label, tag.isBad)
}

export function getOutputTagWarningsFromModelTag(
  tag?: GqlModelTagBaseFragment,
  modelType?: ModelTypes | null,
): string[] {
  if (!tag) return []
  return getOutputWarnings(modelType, tag.metadata?.label, tag.metadata?.isBad)
}

function getOutputWarnings(
  modelType?: ModelTypes | null,
  label?: string | null,
  isBad?: boolean | null,
): string[] {
  const warnings = []
  switch (label ?? 'Unlabeled') {
    case 'Few Values':
      warnings.push(
        'This tag has too few values and is likely to result in low quality predictions.',
      )
      break
    case 'Noise':
      if (modelType === 'AnomalyModel') {
        warnings.push(
          'The signal of this tag is considered noisy as it frequently changes direction with significant changes in amplitude and it is likely to result in low quality predictions.',
        )
      }
      break
    case 'Unlabeled':
      warnings.push(
        'This tag needs to be analyzed and labeled before use. Labeling tags is currently a process that has to be started manually, so contact Intelecy if you want to use this tag.',
      )
      break
    case 'Step':
    case 'Bool':
      warnings.push(
        'Currently there is no support for selecting categorical values like step and bool as output tags.',
      )
      break
    case 'String':
      warnings.push(
        'Currently tags of type string are not supported as output tags.',
      )
      break
    case 'Too Many Values':
      warnings.push(
        'This tag has too many measurements to be used as model output.',
      )
      break
    default:
      break
  }

  if (isBad) {
    warnings.push(
      'More than 10% of the values are considered unreliable due to problems with sensor communication.',
    )
  }

  return warnings
}
