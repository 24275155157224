import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query'
import { ClientError } from 'graphql-request'
import * as api from 'src/services'
import { viewerQueryKey } from 'account/api'
import { SITE_USERS } from '../site.get'
import { SITE_INVITATIONS_QUERY } from './invitations.get'

export function useAcceptSiteInvitation(): UseMutationResult<
  api.GqlAcceptFactoryInvitationMutation,
  ClientError | Error,
  api.GqlAcceptFactoryInvitationInput
> {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: api.acceptInvitation,
    onSuccess: () => {
      queryClient.invalidateQueries([viewerQueryKey])
    },
  })
}

export function useDeclineSiteInvitation(): UseMutationResult<
  api.GqlDeclineFactoryInvitationMutation,
  ClientError | Error,
  api.GqlDeclineFactoryInvitationInput
> {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: api.declineInvitation,
    onSuccess: () => {
      queryClient.invalidateQueries([viewerQueryKey])
    },
  })
}

export function useCancelSiteInvitation(
  siteId: string,
): UseMutationResult<
  api.GqlCancelFactoryInvitationMutation,
  ClientError | Error,
  api.GqlCancelFactoryInvitationInput
> {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: api.cancelInvitation,
    onSuccess: () => {
      queryClient.invalidateQueries([SITE_USERS, siteId])
      queryClient.invalidateQueries([SITE_INVITATIONS_QUERY, siteId])
    },
  })
}
