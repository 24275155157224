import { useEffect, useMemo, useRef, useState } from 'react'
import { debounce } from 'lodash'
import { TimeSeriesChart } from 'src/components/ui'
import { tsdArrayMinMax } from 'src/utility/timeSeries'
import { GqlErrorScoreKind, GqlThresholdKind, GqlTransform } from 'src/services'
import { theme } from 'tailwind.config'
import { useModelAnomalyScore } from '../anomalyThresholds.api'
import { Data } from '../anomalyThresholds.types'
import { AnomalyStatusTimeline } from './AnomalyStatusTimeline'
import ThresholdSlider from './ThresholdSlider'

interface AnomalyThresholdInputProps {
  id: string // model id
  errorScoreKind: GqlErrorScoreKind
  transform: GqlTransform
  value: number
  onChange: (value: number) => void
  thresholdType?: GqlThresholdKind
  readOnly?: boolean
}

export function AnomalyThresholdInput({
  id,
  value,
  onChange,
  errorScoreKind,
  transform,
  thresholdType,
  readOnly,
}: AnomalyThresholdInputProps): JSX.Element {
  const [yAxisRange, setYAxisRange] = useState({ min: 0, max: 1 })
  const [extreme, setExtreme] = useState<number>()
  const {
    colors: { primary },
  } = theme

  const anomalyScore = useModelAnomalyScore({ id, transform, errorScoreKind })
  const data: Data[] =
    anomalyScore.data?.map(([ts, , , max]) => [ts, max]) ?? []

  const setExtremesDebounced = useMemo(
    () => debounce(o => setExtreme(o), 1000),
    [setExtreme],
  )
  const valueRef = useRef(value)

  useEffect(() => {
    if (anomalyScore.data) {
      const [min, max] = tsdArrayMinMax(anomalyScore.data)

      const extremeValue = Math.max(
        Math.abs(min),
        Math.abs(max),
        Math.abs((value ?? 0) * 1.2),
      )

      if (value !== valueRef.current) {
        setExtremesDebounced(extremeValue)
      } else {
        setExtreme(extremeValue)
      }

      valueRef.current = value
    }
  }, [anomalyScore.data, value, setExtremesDebounced])

  const yAxis =
    errorScoreKind === 'SIGNED' && extreme
      ? {
          min: -extreme,
          max: extreme,
        }
      : {
          min: 0,
          max: extreme,
        }

  return (
    <div className="flex size-full flex-col gap-s">
      <div className="w-full">
        <div className="relative">
          <TimeSeriesChart
            tooltip
            threshold={value}
            data={[
              {
                name: 'error',
                data,
                color: primary,
              },
            ]}
            setYAxisRange={setYAxisRange}
            isPending={anomalyScore.isLoading}
            desaturate
            thresholdType={thresholdType}
            errorScoreKind={errorScoreKind}
            yAxis={yAxis}
          />
          {!readOnly && thresholdType && (
            <ThresholdSlider
              value={value}
              onChange={onChange}
              yAxisRange={yAxisRange}
              thresholdType={thresholdType}
            />
          )}
        </div>
      </div>
      <AnomalyStatusTimeline
        data={data}
        threshold={value}
        thresholdType={thresholdType}
      />
    </div>
  )
}
