import { UseQueryResult, useQuery } from '@tanstack/react-query'
import * as api from 'src/services'
import { formatISO } from 'date-fns'

const ANOMALIES = 'anomalies'

export function useAnomalies(
  factory: string,
  from: number,
  to: number,
): UseQueryResult<api.GqlAnomalyBaseFragment[], Error> {
  return useQuery({
    queryKey: [ANOMALIES, from, to, factory],
    queryFn: () => fetchAnomalies(factory, from, to),
  })
}

async function fetchAnomalies(
  factory: string,
  from: number,
  to: number,
): Promise<api.GqlAnomalyBaseFragment[]> {
  const f = formatISO(from)
  const t = formatISO(to)
  return api.fetchAnomalies(factory, f, t)
}

export default useAnomalies
