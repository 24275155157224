import React from 'react'
import classNames from 'classnames'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { IconDefinition } from '@fortawesome/pro-light-svg-icons'
import { Text } from '../typography'
import { Icon } from '../icons'

type Variant = {
  [key: string]: {
    icon: IconDefinition
    bgStyle: string
    borderStyle: string
    textStyle: string
  }
}

const variants: Variant = {
  success: {
    icon: regular('circle-check'),
    bgStyle: 'bg-alert-success-bg',
    borderStyle: 'border-alert-success',
    textStyle: 'text-alert-success',
  },
  info: {
    icon: regular('circle-info'),
    bgStyle: 'bg-alert-info-bg',
    borderStyle: 'border-alert-info',
    textStyle: 'text-alert-info',
  },
  warning: {
    icon: regular('triangle-exclamation'),
    bgStyle: 'bg-alert-warning-bg',
    borderStyle: 'border-alert-warning',
    textStyle: 'text-alert-warning',
  },
  error: {
    icon: regular('circle-minus'),
    bgStyle: 'bg-alert-error-bg',
    borderStyle: 'border-alert-error',
    textStyle: 'text-alert-error',
  },
}

interface Props extends React.ComponentPropsWithoutRef<'div'> {
  variant: keyof typeof variants
  primaryMessage: string
  secondaryMessage?: string
}

export default function Alert({
  variant,
  primaryMessage,
  secondaryMessage,
}: Props): JSX.Element {
  const v = variants[variant]
  const multiline = secondaryMessage && secondaryMessage.length > 0
  return (
    <div
      className={classNames(
        v.bgStyle,
        v.borderStyle,
        'border-2 border-solid border-l-4 rounded p-2 inline-block w-full',
        'grid grid-cols-[min-content,auto] items-center',
      )}
    >
      <div className="row-span-full">
        <Icon
          icon={variants[variant].icon}
          size="big"
          className={classNames(v.textStyle, 'mr-2')}
        />
      </div>
      <div>
        <Text variant="content" bold={true}>
          {primaryMessage}
        </Text>
        {multiline && <Text variant="content">{secondaryMessage}</Text>}
      </div>
    </div>
  )
}
