import { graphqlApi } from './graphQL'
import {
  GqlMeFragment,
  GqlUpdateUserConsentMutation,
  GqlAddUserMutation,
  GqlInviteFactoryUserMutation,
  GqlInviteFactoryUserInput,
  GqlAcceptFactoryInvitationInput,
  GqlAcceptFactoryInvitationMutation,
  GqlCancelFactoryInvitationInput,
  GqlCancelFactoryInvitationMutation,
  GqlDeclineFactoryInvitationInput,
  GqlDeclineFactoryInvitationMutation,
  GqlUpdateUserConsentInput,
  GqlAddUserInput,
  GqlDisableUserInput,
  GqlDisableUserMutation,
} from './graphqlTypes'

export async function fetchMe(): Promise<GqlMeFragment> {
  const api = await graphqlApi()
  const data = await api.Me()
  return data.me
}

export async function updateUserConsent({
  cookieConsent,
  dataCollectionConsent,
}: GqlUpdateUserConsentInput): Promise<GqlUpdateUserConsentMutation> {
  const api = await graphqlApi()
  const data = await api.UpdateUserConsent({
    input: {
      cookieConsent,
      dataCollectionConsent,
    },
  })
  return data
}

export async function addUser({
  customerId,
  role,
  name,
  factoryRoles,
  email,
}: GqlAddUserInput): Promise<GqlAddUserMutation> {
  const api = await graphqlApi()
  const data = await api.AddUser({
    input: {
      customerId,
      factoryRoles,
      role,
      name,
      email,
    },
  })
  return data
}

export async function disableUser({
  id,
}: GqlDisableUserInput): Promise<GqlDisableUserMutation> {
  const api = await graphqlApi()
  const data = await api.DisableUser({
    input: {
      id,
    },
  })
  return data
}

export async function inviteUser({
  factoryId,
  role,
  email,
}: GqlInviteFactoryUserInput): Promise<GqlInviteFactoryUserMutation> {
  const api = await graphqlApi()
  const data = await api.InviteFactoryUser({
    input: {
      factoryId,
      role,
      email,
    },
  })
  return data
}

export async function acceptInvitation({
  invitationId,
}: GqlAcceptFactoryInvitationInput): Promise<GqlAcceptFactoryInvitationMutation> {
  const api = await graphqlApi()
  const data = await api.AcceptFactoryInvitation({
    input: {
      invitationId,
    },
  })
  return data
}

export async function declineInvitation({
  invitationId,
}: GqlDeclineFactoryInvitationInput): Promise<GqlDeclineFactoryInvitationMutation> {
  const api = await graphqlApi()
  const data = await api.DeclineFactoryInvitation({
    input: {
      invitationId,
    },
  })
  return data
}

export async function cancelInvitation({
  invitationId,
}: GqlCancelFactoryInvitationInput): Promise<GqlCancelFactoryInvitationMutation> {
  const api = await graphqlApi()
  const data = await api.CancelFactoryInvitation({
    input: {
      invitationId,
    },
  })
  return data
}
