import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, Icon, Text, Modal } from 'src/components/ui'

interface CrossProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
}

const Cross = ({ onClick }: CrossProps): JSX.Element => (
  <button
    className="absolute right-xs top-xs z-[2] cursor-pointer border-none bg-white p-2xs text-[1.5rem] leading-[0]"
    onClick={onClick}
  >
    <Icon icon={light('xmark')} />
  </button>
)

interface ConfirmModalProps {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  renderConfirmButton: () => any
  text: JSX.Element | string
  subText?: JSX.Element | string
  pending: boolean
}

export function ConfirmModal({
  isOpen,
  setIsOpen,
  renderConfirmButton,
  text,
  subText,
  pending,
}: ConfirmModalProps): JSX.Element {
  return (
    <Modal
      isOpen={isOpen}
      close={() => setIsOpen(false)}
      customStyles="w-[90%] max-w-[512px]"
    >
      <div className="relative p-[1em]">
        <Cross onClick={() => setIsOpen(false)} />
        <Text variant="title" className="mb-xs">
          {text}
        </Text>
        {subText && <Text>{subText}</Text>}
        <div className="mt-[1em] flex justify-end gap-[0.5em]">
          {!pending && (
            <Button
              variant="secondary"
              title="Cancel"
              onClick={() => setIsOpen(false)}
            />
          )}
          {renderConfirmButton()}
        </div>
      </div>
    </Modal>
  )
}
