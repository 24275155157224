import { useParams } from 'react-router-dom'
import { StreamlitConnector, StreamlitVerifier } from 'src/streamlitApp'
import { ErrorDisplay } from 'pages/app'
import { DataExplorerLandingPage } from './DataExplorerLandingPage'
import { useSiteDexAccess } from './dataExplorer.api'

export function DataExplorerPage(): JSX.Element {
  const { siteId, sessionId } = useParams()
  if (!siteId) throw new Error('`siteId` route param missing')
  const dexAccessQuery = useSiteDexAccess({ siteId })

  if (dexAccessQuery.isSuccess) {
    if (dexAccessQuery.data) {
      if (sessionId) {
        return <StreamlitVerifier slug="dex" sessionId={sessionId} />
      }
      return <StreamlitConnector factory={siteId} slug="dex" />
    }
    return <DataExplorerLandingPage />
  }

  if (dexAccessQuery.isError) {
    return <ErrorDisplay error={dexAccessQuery.error} />
  }

  // Loading ...
  return <></>
}
