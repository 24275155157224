import { useCallback, useState } from 'react'
import { anomalyDuration, isDefined } from 'src/types'
import { useTitle } from 'src/utility'
import { useSite } from 'src/contexts/site'
import { useNavigate } from 'react-router-dom'
import {
  DynamicTable,
  Text,
  AutocompleteTextInput,
  Spinner,
  TimePickerNavigation,
  Checkbox,
} from 'src/components/ui'
import { uniq } from 'lodash'
import { parseISO } from 'date-fns'
import { useFavouriteModelsIds } from 'models/api'
import { label } from 'models/model/model.utils'
import { ErrorDisplay } from 'pages/app'
import useTimeRange, { TimeRangeProvider } from 'src/contexts/timeRange'
import { TableAnomaly } from '../anomaly.types'
import { AnomalyStatusFilter } from './components'
import { useAnomalies } from './anomalies.api'
import { getAnomalyTableConfig } from './anomaliesTable.config'
import { useAnomaliesFilter } from './useAnomaliesFilter'

const Anomalies = (): JSX.Element => {
  useTitle('Anomalies')
  const { rootLink, id: factory } = useSite()
  const navigate = useNavigate()
  const {
    favoriteModels,
    filter,
    statuses,
    page,
    pageSize,
    setFilter,
    setStatuses,
    setPage,
    setPageSize,
    setFavoriteModels,
  } = useAnomaliesFilter(['NEW', 'IN_PROGRESS'])
  const { timeRange } = useTimeRange()
  const anomaliesQuery = useAnomalies(factory, timeRange.from, timeRange.to)
  const favouriteModelsIdsQuery = useFavouriteModelsIds()

  const actions = {
    selectRow: ({ id }: any) => {
      navigate(`${rootLink}/anomalies/${id}`)
    },
  }

  const tableAnomalies =
    anomaliesQuery.data
      ?.map<TableAnomaly>(item => {
        return {
          ...item,
          commentsCount: item.comments.length ?? 0,
          duration: anomalyDuration({
            ...item,
            to: item.end ? parseISO(item.end).valueOf() : undefined,
            from: parseISO(item.start).valueOf(),
          }),
          id: item.id.toString(),
          from: parseISO(item.start).valueOf(),
          status: item.state,
          modelId: item.model.id,
          modelName: item.model.name,
          modelState: label(item.model.state),
          tagName: item.model.tag.displayName ?? item.model.tag.tagName,
          tag: item.model.tag,
          to: item.end ? parseISO(item.end).valueOf() : undefined,
          link: `${rootLink}/anomalies/${item.id}`,
        }
      })
      .filter(a => {
        if (favoriteModels) {
          return favouriteModelsIdsQuery.data?.has(a.modelId)
        } else return true
      })
      .sort((a, b) => b.from - a.from) ?? []

  const doesExternalFilterPass = useCallback(
    ({ data: anomaly }: { data: TableAnomaly }): boolean => {
      if (filter) {
        const filterBy = {
          modelName: anomaly.modelName,
          tagName: anomaly.tag?.displayName || anomaly.tagName,
        }
        const show = filter
          .toLowerCase()
          .split(' ')
          .every(word => {
            return Object.values(filterBy).some(value =>
              value?.toLowerCase().includes(word),
            )
          })
        if (!show) return false
      }
      if (statuses.length > 0) {
        return isDefined(anomaly.status) && statuses.includes(anomaly.status)
      }
      return true
    },
    [filter, statuses],
  )

  const isExternalFilterPresent = useCallback((): boolean => {
    return !!filter || !!statuses.length
  }, [filter, statuses])

  if (anomaliesQuery.isError || favouriteModelsIdsQuery.isError) {
    const errorQuery = anomaliesQuery.isError
      ? anomaliesQuery
      : favouriteModelsIdsQuery
    return (
      <ErrorDisplay
        error={errorQuery.error}
        message="Something went wrong"
        action={errorQuery.refetch}
      />
    )
  }

  return (
    <div className="grid h-full max-h-[calc(100vh-50px)] grid-rows-[auto_auto_minmax(0,1fr)] gap-[1em] p-[1em] pt-0">
      <div className="flex w-full items-center justify-between gap-[1em]">
        <div className="w-full max-w-[20em]">
          <AutocompleteTextInput
            placeholder="Filter by model name"
            options={uniq(
              tableAnomalies
                .filter(anomaly => doesExternalFilterPass({ data: anomaly }))
                .map(a => a.modelName!),
            )}
            onChange={setFilter}
            value={filter}
          />
        </div>
        <TimePickerNavigation />
      </div>
      <div className="flex items-center gap-s">
        <div className="grid grid-flow-col items-center justify-start gap-[0.3em]">
          <label htmlFor="status-filter">
            <Text>Filter by status</Text>
          </label>
          <AnomalyStatusFilter
            statusFilter={statuses}
            setStatusFilter={setStatuses}
          />
        </div>
        <div
          className="flex items-center"
          onClick={() => {
            setFavoriteModels(!favoriteModels)
          }}
        >
          <Checkbox value={favoriteModels} onChange={setFavoriteModels} />
          <label className="ml-2xs">
            <Text className="inline-block">Only favorite models</Text>
          </label>
        </div>
      </div>
      {anomaliesQuery.isLoading || favouriteModelsIdsQuery.isLoading ? (
        <Spinner />
      ) : (
        <DynamicTable
          id="Anomalies"
          data={tableAnomalies}
          config={getAnomalyTableConfig(filter)}
          actions={actions}
          rowHeight={48}
          isExternalFilterPresent={isExternalFilterPresent}
          doesExternalFilterPass={doesExternalFilterPass}
          currentPage={page}
          currentPageSize={pageSize}
          onPageChange={setPage}
          onPageSizeChange={setPageSize}
        />
      )}
    </div>
  )
}

export function AnomaliesPage(): JSX.Element {
  return (
    <TimeRangeProvider urlQuery>
      <Anomalies />
    </TimeRangeProvider>
  )
}
