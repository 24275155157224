import TooltipTrigger from 'react-popper-tooltip'
import classNames from 'classnames'

type Direction = 'top' | 'bottom' | 'right' | 'left' | 'bottom-start'

function getTooltipStyles(placement: string): string {
  switch (placement) {
    case 'top':
      return 'mb-[1em] after:bottom-[-1em] after:left-[50%] after:translate-x-[-50%] after:border-t-black-secondary'
    case 'bottom':
      return 'mt-[1em] after:top-[-1em] after:left-[50%] after:translate-x-[-50%] after:border-b-black-secondary'
    case 'right':
      return 'ml-[1em] after:left-[-1em] after:top-[50%] after:translate-y-[-50%] after:border-r-black-secondary'
    case 'left':
      return 'mr-[1em] after:right-[-1em] after:top-[50%] after:translate-y-[-50%] after:border-l-black-secondary'
    default:
      return ''
  }
}

interface TooltipProps {
  render: any
  direction?: Direction // apparently a subset of Popper.Placement
  isOpen?: boolean
  children?: React.ReactNode
  zIndex?: number
  className?: string
  containerClassName?: string
}

export function Tooltip({
  children,
  render,
  direction = 'left',
  isOpen,
  zIndex,
  className,
  containerClassName,
}: TooltipProps): JSX.Element {
  return (
    <TooltipTrigger
      placement={direction}
      trigger="hover"
      tooltipShown={isOpen}
      delayShow={100}
      delayHide={100}
      tooltip={({ tooltipRef, getTooltipProps, placement }) => (
        <div
          {...getTooltipProps({
            ref: tooltipRef,
            className: classNames(
              'tooltip-container',
              'inline-block max-w-[300px] break-words whitespace-normal bg-black-secondary text-white p-[1em] rounded-xs text-[0.9em] relative z-[1]',
              "after:absolute after:content-[''] after:h-0 after:w-0 after:border-[0.5em] after:border-solid after:border-transparent",
              getTooltipStyles(placement),
              className,
            ),
            style: { zIndex },
          })}
          autoCapitalize="off"
        >
          {render()}
        </div>
      )}
    >
      {({ getTriggerProps, triggerRef }) => (
        <div
          data-testid="tooltip-trigger"
          {...getTriggerProps({
            ref: triggerRef,
            className: classNames(
              'trigger inline-block relative',
              containerClassName,
            ),
          })}
        >
          {children || (
            <div className="size-[1.5em] rounded-full border border-solid border-blue-edit text-center text-blue-edit">
              ?
            </div>
          )}
        </div>
      )}
    </TooltipTrigger>
  )
}
