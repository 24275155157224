import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useRef, useState } from 'react'
import { useModal } from 'react-modal-hook'
import { useParams } from 'react-router-dom'
import { SiteUser, SiteRole, siteRoleToGql } from 'src/types'
import { DynamicTable, Button, Text } from 'src/components/ui'
import { useAuth } from 'src/contexts/auth'
import {
  AddSiteUserModal,
  CancelInvitationModal,
  RemoveUserModal,
  UserType,
} from 'orgs-sites/users'
import {
  useCancelSiteInvitation,
  useSite,
  useSiteInvitations,
  useSiteUsers,
  useUserSiteRoleMutation,
} from 'orgs-sites/site/api'
import { siteUsersTableConfig } from './SiteUsers.table.config'

enum OpenModal {
  DELETE_USER = 'DELETE_USER',
  REMOVE_USER = 'REMOVE_USER',
  CANCEL_INVITATION = 'CANCEL_INVITATION',
  NONE = 'NONE',
}

export function SiteUsers(): JSX.Element {
  const [openModal, setOpenModal] = useState<OpenModal>(OpenModal.NONE)
  const selectedUserRef = useRef<SiteUser>()
  const { orgId, siteId } = useParams()
  if (!siteId || !orgId) throw new Error('Params Missing')
  const { viewer } = useAuth()
  const { data: siteUsers } = useSiteUsers({
    siteId,
  })
  const siteInvitationQuery = useSiteInvitations(siteId)

  const site = useSite(siteId).data

  const cancelSiteInvitationMutation = useCancelSiteInvitation(siteId)
  const updateSiteRoleMutation = useUserSiteRoleMutation()

  const openCancelInvitationModal = (id: string): void => {
    selectedUserRef.current = siteUsers?.find(user => user.id === id)
    setOpenModal(OpenModal.CANCEL_INVITATION)
  }

  const cancelInvitation = async (): Promise<void> => {
    const invitation = siteInvitationQuery.data?.find(
      invite =>
        invite.invitee.id === selectedUserRef.current?.id &&
        invite.state === 'ACTIVE',
    )
    if (invitation)
      await cancelSiteInvitationMutation.mutateAsync(
        { invitationId: invitation.id },
        {
          onSuccess: () => {
            setOpenModal(OpenModal.NONE)
          },
        },
      )
  }

  function closeModal(): void {
    setOpenModal(OpenModal.NONE)
  }

  const handleChangeUserRole = async (
    selectedRole: SiteRole,
    userId: string,
  ): Promise<void> => {
    await updateSiteRoleMutation.mutateAsync({
      userId,
      role: siteRoleToGql(selectedRole),
      factoryId: siteId,
    })
  }

  function findUserName(): string {
    const user = siteUsers?.find(
      user => user.id === selectedUserRef.current?.id,
    )
    return user?.name || user?.email || ''
  }

  const [showAddSiteUserModal, closeAddSiteUserModal] = useModal(
    () =>
      site ? (
        <AddSiteUserModal site={site} onClose={closeAddSiteUserModal} />
      ) : null,
    [site],
  )

  const [showRemoveSiteUserModal, closeRemoveSiteUserModal] = useModal(
    () =>
      selectedUserRef.current ? (
        <RemoveUserModal
          siteId={siteId}
          type={UserType.SITE_USER}
          user={selectedUserRef.current}
          onClose={closeRemoveSiteUserModal}
          content={`Removing ${findUserName()} will prevent them from accessing this site.`}
        />
      ) : null,
    [siteUsers],
  )

  const openRemoveUserModal = (id: string): void => {
    const user = siteUsers?.find(user => user.id === id)
    if (!user) return
    selectedUserRef.current = user
    showRemoveSiteUserModal()
  }

  return (
    <div className="mt-s flex flex-1 flex-col">
      <div className="mb-s flex items-center justify-between">
        <Text variant="description" className="mb-xs">
          Lists all of the users assigned to this site.
        </Text>
        {site?.viewerRole === SiteRole.ADMIN && (
          <Button
            variant="icon-primary"
            icon={regular('plus-circle')}
            title="Add User"
            onClick={showAddSiteUserModal}
          />
        )}
      </div>
      {orgId && (
        <DynamicTable
          id="SiteUsers"
          allowOverflow
          headerSummary={`${
            siteUsers?.filter(user => user.status === 'Active').length || 0
          } Users | ${
            siteUsers?.filter(user => user.status === 'Pending').length || 0
          } Pending`}
          data={
            siteUsers?.sort((a, b) => {
              if (a.id === viewer.id) return -1
              if (b.id === viewer.id) return 1
              return 0
            }) || []
          }
          config={siteUsersTableConfig({
            orgId,
            openRemoveUserModal,
            openCancelInvitationModal,
            onRoleChange: handleChangeUserRole,
            currentUser: siteUsers?.find(u => u.id === viewer.id),
          })}
          rowHeight={48}
        />
      )}
      <CancelInvitationModal
        onConfirm={cancelInvitation}
        userName={findUserName()}
        isOpen={openModal === OpenModal.CANCEL_INVITATION}
        onClose={closeModal}
      />
    </div>
  )
}
