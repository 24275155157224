import { UseMutationResult, useMutation } from '@tanstack/react-query'
import {
  GqlBrowseNodeFragment,
  GqlNodeValuesFragment,
  browseOpcConnection,
  fetchNodeValues,
} from 'src/services'

export enum FetchType {
  HIERARCHY = 'hierarchy',
  VALUES = 'values',
  HIERARCHY_AND_VALUES = 'hierarchyAndValues',
}

const UNSUPPORTED_DATA_TYPES = ['byte', 'i=15', 'int8', 'i=16', 'i=862']

type Props = {
  connectionId: string
  fetchType: FetchType
  nodeId?: string
}

type NodeDataResponse = {
  nodeData?: GqlBrowseNodeFragment
  values?: GqlNodeValuesFragment[]
}

export async function fetchNodeData({
  connectionId,
  fetchType,
  nodeId = 'i=85',
}: Props): Promise<NodeDataResponse> {
  const nodeData = await browseOpcConnection(connectionId, nodeId).catch(() => {
    throw new Error('Failed to fetch node data')
  })
  // Fetch Values if needed
  let values: GqlNodeValuesFragment[] = []
  if (fetchType !== FetchType.HIERARCHY) {
    // // Get all of the nodeIds for variable opc nodes
    const nodes = nodeData?.children?.filter(
      el =>
        el.__typename === 'VariableOpcNode' &&
        !UNSUPPORTED_DATA_TYPES.includes(el.dataType ?? ''),
    )
    const nodeIds = nodes?.map(node => node.nodeId)
    if (nodeIds?.length) {
      const res = await fetchNodeValues(connectionId, nodeIds).catch(() => {
        throw new Error('Failed to fetch node values')
      })
      values = res ?? []
    }
  }
  return {
    nodeData,
    values,
  }
}

export function useFetchNodeValuesMutation(
  connectionId: string,
): UseMutationResult<GqlNodeValuesFragment[], unknown, string[]> {
  return useMutation({
    mutationFn: async (nodeIds: string[]) => {
      const res = await fetchNodeValues(connectionId, nodeIds)
      if (!res) throw new Error('Failed to fetch specific node value')
      return res
    },
  })
}
