import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ChartAxis, ChartOptions, SeriesOptions } from 'src/types/chartTypes'
import { Icon, Text, Tooltip, Spinner } from 'src/components/ui'
import classNames from 'classnames'
import { ChartData } from '../../useChartData'
import ScatterGraphSettings from './ScatterGraphSettings'

interface TagProps {
  tagName?: string
  engUnit?: string
  displayName?: string
  description?: string
}

const tagUnit = (unit?: string): string => {
  if (unit && !['None', '-'].includes(unit)) {
    return `${unit}`
  }
  return 'unit is missing'
}

const tagInfo = (tag?: TagProps): JSX.Element => {
  if (tag) {
    const displayName = tag.displayName || tag.tagName
    return (
      <div className="grid w-full grid-cols-[1fr_1fr_80px] items-center gap-xs">
        <span title={tag.tagName} className="overflow-hidden whitespace-nowrap">
          <Text variant="description" bold>
            {displayName}
          </Text>
        </span>
        <span
          title={tag.description}
          className="overflow-hidden whitespace-nowrap"
        >
          <Text variant="description">{tag.description}</Text>
        </span>
        <span
          title={tag.engUnit}
          className="overflow-hidden whitespace-nowrap text-right"
        >
          <Text variant="description" bold>
            {tagUnit(tag.engUnit)}
          </Text>
        </span>
      </div>
    )
  }
  return <Text variant="content">unknown</Text>
}

const spinner = (
  <>
    {' '}
    <Spinner small inline />
  </>
)

const TagLegend = (props: TagProps): JSX.Element => {
  return props.tagName ? tagInfo(props) : spinner
}

interface ModelLegendProps {
  name?: string
  type?: {
    name?: string
  }
  tag?: TagProps
}

const ModelLegend = (props: ModelLegendProps): JSX.Element =>
  props.name ? (
    <div className="grid w-full grid-cols-[1fr_1fr_80px] items-center gap-xs">
      <span className="overflow-hidden whitespace-nowrap">
        <Text variant="description" bold>
          {props.type?.name} model: {props.name}
        </Text>
      </span>
      <span className="overflow-hidden whitespace-nowrap">
        <Text variant="description">
          {props.tag?.displayName ?? props.tag?.tagName}
        </Text>
      </span>
      <span className="overflow-hidden whitespace-nowrap text-right">
        <Text variant="description" bold>
          {tagUnit(props.tag?.engUnit)}
        </Text>
      </span>
    </div>
  ) : (
    spinner
  )

interface AnomalyScoreLegendProps {
  name?: string
  tag?: TagProps
}

const SpikeScoreLegend = ({
  name,
  tag,
}: AnomalyScoreLegendProps): JSX.Element =>
  name ? (
    <div className="grid w-full grid-cols-[1fr_1fr_80px] items-center gap-xs">
      <span className="overflow-hidden whitespace-nowrap">
        <Text variant="description" bold>
          Spike anomaly score for {name}
        </Text>
      </span>
      <span className="overflow-hidden whitespace-nowrap">
        <Text variant="description">{tag?.displayName ?? tag?.tagName}</Text>
      </span>
      <span className="overflow-hidden whitespace-nowrap text-right">
        <Text variant="description" bold>
          {tagUnit(tag?.engUnit)}
        </Text>
      </span>
    </div>
  ) : (
    spinner
  )

const ShortScoreLegend = ({
  name,
  tag,
}: AnomalyScoreLegendProps): JSX.Element =>
  name ? (
    <div className="grid w-full grid-cols-[1fr_1fr_80px] items-center gap-xs">
      <span className="overflow-hidden whitespace-nowrap">
        <Text variant="description" bold>
          Short anomaly score for {name}
        </Text>
      </span>
      <span className="overflow-hidden whitespace-nowrap">
        <Text variant="description">{tag?.displayName ?? tag?.tagName}</Text>
      </span>
      <span className="overflow-hidden whitespace-nowrap text-right">
        <Text variant="description" bold>
          {tagUnit(tag?.engUnit)}
        </Text>
      </span>
    </div>
  ) : (
    spinner
  )

type LegendRowProps = {
  readOnly?: boolean
  axis: ChartAxis
  series?: SeriesOptions
  data: ChartData[]
  index: number
  isDataPending: any
  remove?: (index: number) => void
}

const LegendRow = ({
  readOnly,
  axis,
  series,
  data,
  index,
  isDataPending,
  remove,
}: LegendRowProps): JSX.Element => {
  const Component = {
    tag: TagLegend,
    forecast: ModelLegend,
    prediction: ModelLegend,
    spikeScore: SpikeScoreLegend,
    shortScore: ShortScoreLegend,
  }[series?.type ?? 'tag']

  const warnings: string[] = []
  if (data[index]?.data?.length === 0) {
    warnings.push('No data available for this time range')
  }

  return (
    <div
      className={classNames(
        'flex w-full items-center gap-2xs px-xs select-text',
        readOnly ? 'py-[3px]' : 'py-xs',
      )}
    >
      {!series ? (
        <Text variant="description" className="text-grey-text">
          Select {axis.toUpperCase()} axis
        </Text>
      ) : (
        <>
          <div className="flex w-full flex-1">
            <Component {...data[index]} />
          </div>
          <div className="flex w-[50px] items-center justify-end gap-2xs">
            {!isDataPending && warnings.length > 0 && (
              <Tooltip
                render={() =>
                  warnings.map(warning => <div key={warning}>{warning}</div>)
                }
              >
                <div style={{ lineHeight: 0 }}>
                  <Icon
                    icon={light('triangle-exclamation')}
                    className="text-delete-primary"
                  />
                </div>
              </Tooltip>
            )}
            {series?.min !== undefined && (
              <Tooltip
                render={() =>
                  `The y-axis has been locked to the range [${
                    series.min ?? 'auto'
                  }, ${series.max ?? 'auto'}]. You can revert this in the menu.`
                }
              >
                <div style={{ lineHeight: 0 }}>
                  <Icon icon={light('lock')} className="text-yellow-fav" />
                </div>
              </Tooltip>
            )}
            {!readOnly && (
              <Icon
                onClick={() => remove && remove(index)}
                icon={light('circle-xmark')}
                className="ml-2xs cursor-pointer text-delete-primary"
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

interface ScatterLegendProps {
  chart: ChartOptions
  data: ChartData[]
  remove?: (index: number) => void
  switchAxis?: () => void
  isDataPending: any
  isModal?: boolean
  readOnly?: boolean
  setChart?: (options: ChartOptions) => void
}

export function ScatterLegend({
  chart,
  data,
  remove,
  switchAxis,
  readOnly,
  isDataPending,
  setChart,
}: ScatterLegendProps): JSX.Element {
  const xIndex = chart.data.findIndex(d => d.axis === ChartAxis.X)
  const yIndex = chart.data.findIndex(d => d.axis === ChartAxis.Y)
  return (
    <div className="flex flex-col gap-2xs px-xs pb-xs">
      {readOnly && <ScatterGraphSettings chart={chart} setChart={setChart} />}
      <div className="flex cursor-default">
        <div
          className={classNames(
            'relative flex flex-col px-xs',
            !readOnly
              ? 'cursor-pointer hover:bg-grey-50 gap-[18px] py-[7px] border border-solid border-grey-50'
              : 'gap-[8px] py-[3px]',
          )}
          onClick={() => !readOnly && switchAxis && switchAxis()}
        >
          <Icon icon={regular('square-x')} />
          <Icon icon={regular('square-y')} />
          {!readOnly && (
            <Icon
              icon={light('arrow-up-arrow-down')}
              size="xsmall"
              className="absolute top-[50%] translate-y-[calc(-50%-1px)]"
            />
          )}
        </div>
        <div className={classNames('flex w-full flex-col')}>
          <LegendRow
            readOnly={readOnly}
            axis={ChartAxis.X}
            series={chart.data[xIndex]}
            data={data}
            index={xIndex}
            isDataPending={isDataPending}
            remove={remove}
          />
          <LegendRow
            readOnly={readOnly}
            axis={ChartAxis.Y}
            series={chart.data[yIndex]}
            data={data}
            index={yIndex}
            isDataPending={isDataPending}
            remove={remove}
          />
        </div>
      </div>
    </div>
  )
}
