import { Outlet, useParams } from 'react-router-dom'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useSite } from 'orgs-sites/site/api'
import { Icon, Text, RoutingTabs, Tooltip, Spinner } from 'src/components/ui'
import { SiteRole } from 'src/types'
import { ErrorDisplay } from 'pages/app'

export function SiteDetailsPage(): JSX.Element {
  const { siteId } = useParams()
  if (!siteId) throw new Error('`siteId` route param missing')
  const { data: site, isLoading, isError, error } = useSite(siteId)
  const { dataSubscriptions } = useFlags()

  if (isLoading) {
    return <Spinner />
  }

  if (isError) {
    return <ErrorDisplay error={error} message="Failed to load site" />
  }

  if (!site) {
    return <div>Site not found</div>
  }

  const gatewaysDisabled = site.viewerRole !== SiteRole.ADMIN

  const tabs = [
    {
      title: 'Site Users',
      path: ``,
    },
    {
      title: 'Gateways',
      path: `gateways`,
      disabled: gatewaysDisabled,
      tooltipText: gatewaysDisabled
        ? "You don't have the necessary role to view"
        : undefined,
    },
  ]

  if (dataSubscriptions) {
    tabs.push({
      title: 'Data Subscriptions',
      path: `data-subscriptions`,
    })
  }

  return (
    <div className="m-s mt-0 flex h-[calc(100%-1em)] flex-col rounded-2xs bg-white p-m">
      <div className="mb-l flex w-full items-start justify-start gap-l">
        <Icon
          icon={light('industry-windows')}
          className="!h-[30px] !w-[36px] text-grey-disabled"
        />
        <div>
          <Text variant="description" className="mb-2xs">
            Site
          </Text>
          <Text variant="content" bold>
            {site.name}
          </Text>
          <Text variant="small" className="mb-xs mt-s">
            Organization
          </Text>
          <Text variant="description" bold className="my-xs">
            {site.orgName}
          </Text>
        </div>
        <div>
          <Text variant="description" className="mb-2xs">
            Your role
          </Text>
          <Text variant="content" bold>
            {site.viewerRole ?? 'None'}
          </Text>
          <Text variant="small" className="mb-xs mt-s">
            Users assigned
          </Text>
          <Text variant="description" bold className="my-xs">
            {site.numOfUsers}
          </Text>
        </div>
        <div className="m-0 flex h-[90px] flex-col justify-end leading-[20px]">
          <Text variant="small" className="!my-xs">
            Gateways
          </Text>
          <Tooltip
            isOpen={!gatewaysDisabled ? false : undefined}
            direction="bottom-start"
            render={() => {
              return <Text>You don't have the necessary role to view</Text>
            }}
          >
            <Text
              variant="description"
              bold
              className={classNames(gatewaysDisabled && 'text-grey-text')}
            >
              {gatewaysDisabled ? 'N/A' : site.numOfGateways}
            </Text>
          </Tooltip>
        </div>
      </div>
      <RoutingTabs tabs={tabs} />
      <Outlet />
    </div>
  )
}
