import React from 'react'
import { Link } from 'react-router-dom'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { Icon, Spinner, Text } from 'src/components/ui'
import { useClickOutside } from 'src/utility'

type Option = {
  label: string | React.ReactNode
  value: string
  to?: string
}

type Props = {
  textVariant?:
    | 'content'
    | 'small'
    | 'code'
    | 'title'
    | 'content-rubik'
    | 'description'
  value?: Option
  bold?: boolean
  options: Option[]
  onChange: (value: string) => void
  className?: string
  leftComponent?: React.ReactNode
  triggerComponent?: React.ReactNode
  direction?: 'up' | 'down'
  placeholder?: string
  dropdownFooter?: React.ReactNode
  disabled?: boolean
  dropdownClassName?: string
  isLoading?: boolean
}

export default function SelectInput({
  textVariant = 'content',
  bold = false,
  value,
  options,
  onChange,
  className,
  leftComponent,
  triggerComponent,
  direction = 'down',
  placeholder,
  dropdownFooter,
  disabled,
  dropdownClassName,
  isLoading,
}: Props): JSX.Element {
  const [isOpen, setIsOpen] = React.useState(false)
  const dropdownRef = React.useRef<HTMLDivElement>(null)

  useClickOutside(dropdownRef, () => {
    return isOpen && setIsOpen(false)
  })

  function getClassNames(): string {
    if (!triggerComponent) {
      return classNames(
        'gap-s rounded-2xs border border-solid bg-white px-xs transition duration-300',
        isOpen ? 'border-green-primary' : 'border-grey-100',
        disabled ? 'opacity-60' : 'cursor-pointer',
        textVariant === 'content' && 'py-[5px]',
        textVariant === 'description' && 'py-[7px]',
      )
    }
    return ''
  }

  return (
    <div
      className={classNames(
        'relative flex items-center justify-between',
        getClassNames(),
        className,
      )}
      onClick={() => {
        if (!disabled) setIsOpen(!isOpen)
      }}
    >
      {triggerComponent ? (
        triggerComponent
      ) : (
        <>
          <div className="flex items-center gap-xs">
            {leftComponent}
            <Text
              variant={textVariant}
              bold={bold}
              className={classNames(!value?.label && 'text-grey-text')}
            >
              {value?.label || placeholder || 'Select...'}
            </Text>
          </div>
          <Icon
            icon={light('chevron-down')}
            className={`transition duration-300 ${isOpen && 'rotate-180'}`}
          />
        </>
      )}
      {isOpen && (
        <div
          className={classNames(
            'absolute right-0 z-20 flex min-w-full rounded-2xs border border-solid border-grey-100 bg-white drop-shadow-dropdown',
            direction === 'up' && 'bottom-[calc(100%+4px)]',
            direction === 'down' && 'top-[calc(100%+4px)]',
            dropdownClassName,
          )}
          ref={dropdownRef}
        >
          <div className="w-full py-xs">
            {isLoading ? (
              <div className="mx-xs">
                <Spinner inline small />
              </div>
            ) : (
              options.map(option => {
                const Tag = option.to ? Link : 'div'
                return (
                  <Tag
                    to={option.to ?? ''}
                    key={option.value}
                    className="flex w-full cursor-pointer items-center gap-xs whitespace-nowrap px-xs py-2xs text-inherit no-underline transition-all hover:bg-grey-50"
                    onClick={() => {
                      onChange(option.value)
                    }}
                  >
                    <Text
                      variant={textVariant}
                      className="whitespace-nowrap py-3xs"
                      bold={bold}
                    >
                      {option.label}
                    </Text>
                  </Tag>
                )
              })
            )}

            {dropdownFooter && dropdownFooter}
          </div>
        </div>
      )}
    </div>
  )
}
