import classNames from 'classnames'
import { GqlFactoryTrendViewFragment } from 'src/services'
import { ViewsContent } from '../ViewsContent'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  views: GqlFactoryTrendViewFragment[]
  viewId?: string
}

export function ViewDropdown({
  isOpen,
  setIsOpen,
  views,
  viewId,
}: Props): JSX.Element {
  return (
    <div
      className={classNames(
        'absolute top-[calc(100%+4px)] left-[60px] z-20 flex flex-col items-center rounded-2xs border border-solid border-grey-100 bg-white p-xs pt-s drop-shadow-dropdown w-[330px]',
        isOpen ? 'block' : 'hidden',
      )}
    >
      <ViewsContent
        views={views}
        onClose={() => setIsOpen(false)}
        viewId={viewId}
      />
    </div>
  )
}
