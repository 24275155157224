import { Navigate } from 'react-router-dom'
import { useTag, useTagGql, useUpdateTagMutation } from 'tags/api'
import { Text, TextInput, Spinner, EditableField } from 'src/components/ui'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { humanDateTime } from 'src/utility/time'
import { useSite } from 'src/contexts/site'
import { SiteRole } from 'src/types'

type Props = {
  tagName: string
}

enum UpdatingTagProperty {
  DISPLAY_NAME = 'displayName',
  DESCRIPTION = 'description',
  UNIT = 'unit',
}

export function TagOverview({ tagName }: Props): JSX.Element {
  const { data: tag, isLoading: isPending } = useTag(tagName)
  // This is only to get the available time range because it's not included in the REST API
  const { data: tagData } = useTagGql(tagName)
  const [updatingTagProperty, setUpdatingTagProperty] =
    useState<UpdatingTagProperty | null>(null)

  const { viewerRole: role } = useSite()

  const { mutateAsync, isLoading } = useUpdateTagMutation()

  if (!tag && !isPending) {
    toast.error(`Tag ${tagName} Not Found`)
    return <Navigate to="/assets" />
  }

  return (
    <div className="rounded-2xs border border-solid border-grey-50 bg-white p-[1em] transition-all hover:border-grey-100">
      {tag && !isPending ? (
        <>
          <EditableField
            initialState={tag.displayName ?? tag.tagName}
            renderDisplay={() => (
              <Text variant="title" bold className="!text-3xl">
                {tag.displayName ?? tag.tagName}
              </Text>
            )}
            renderInput={({ state, setState }) => (
              <TextInput
                value={state}
                onChange={e => setState(e.target.value)}
                containerStyles="!py-0"
                inputStyles="!text-2xl "
              />
            )}
            updateStatus={
              isLoading &&
              updatingTagProperty === UpdatingTagProperty.DISPLAY_NAME
                ? 'loading'
                : 'success'
            }
            onSave={async state => {
              setUpdatingTagProperty(UpdatingTagProperty.DISPLAY_NAME)
              await mutateAsync({
                tagId: tag.tagNodeId,
                displayName: state,
              })
              setUpdatingTagProperty(null)
            }}
            isEditable={role === SiteRole.ADMIN}
            saveDisabled={state => state === (tag.displayName ?? tag.tagName)}
          />
          <Text className="my-2xs mt-s">ID: {tag.tagNodeId}</Text>
          <div className="flex items-center gap-2xs">
            <Text>Unit: </Text>
            <EditableField
              initialState={tag.engUnit}
              renderDisplay={() => <Text>{tag.engUnit || '/'}</Text>}
              renderInput={({ state, setState }) => (
                <TextInput
                  value={state ?? ''}
                  onChange={e => setState(e.target.value)}
                />
              )}
              updateStatus={
                isLoading && updatingTagProperty === UpdatingTagProperty.UNIT
                  ? 'loading'
                  : 'success'
              }
              onSave={async state => {
                setUpdatingTagProperty(UpdatingTagProperty.UNIT)
                await mutateAsync({
                  tagId: tag.tagNodeId,
                  engUnit: state,
                })
                setUpdatingTagProperty(null)
              }}
              isEditable={role === SiteRole.ADMIN}
              saveDisabled={state => state === tag.engUnit}
            />
          </div>
          <div className="flex items-center gap-2xs">
            <Text>Description: </Text>
            <EditableField
              initialState={tag.description}
              renderDisplay={() => <Text>{tag.description || '/'}</Text>}
              renderInput={({ state, setState }) => (
                <TextInput
                  value={state ?? ''}
                  onChange={e => setState(e.target.value)}
                />
              )}
              updateStatus={
                isLoading &&
                updatingTagProperty === UpdatingTagProperty.DESCRIPTION
                  ? 'loading'
                  : 'success'
              }
              onSave={async state => {
                setUpdatingTagProperty(UpdatingTagProperty.DESCRIPTION)
                await mutateAsync({
                  tagId: tag.tagNodeId,
                  description: state,
                })
                setUpdatingTagProperty(null)
              }}
              isEditable={role === SiteRole.ADMIN}
              saveDisabled={state => state === tag.description}
            />
          </div>
          {tagData?.availableDataTimeRange && (
            <>
              <Text className="mt-xs">
                First data point:{' '}
                {humanDateTime(tagData.availableDataTimeRange.min)}
              </Text>
              <Text className="mt-xs">
                Last data point:{' '}
                {humanDateTime(tagData.availableDataTimeRange.max)}
              </Text>
            </>
          )}
          <Text className="mt-xs">
            Parent name: {tag.parentName ?? 'Unassigned'}
          </Text>
        </>
      ) : (
        <Spinner />
      )}
    </div>
  )
}
