import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { Icon } from '../icons'
import { Text } from '../typography'

const variants = {
  info: {
    icon: light('circle-info'),
    boxStyles: 'bg-off-blue border-blue-edit',
    iconColor: 'text-blue-edit',
  },
  success: {
    icon: light('circle-check'),
    boxStyles: 'bg-off-green border-green-primary',
    iconColor: 'text-green-primary',
  },
  warning: {
    icon: light('triangle-exclamation'),
    boxStyles: 'bg-off-yellow border-yellow-fav',
    iconColor: 'text-yellow-fav',
  },
  error: {
    icon: light('circle-minus'),
    boxStyles: 'bg-delete-20 border-delete-primary',
    iconColor: 'text-delete-primary',
  },
  custom: {
    icon: null,
    boxStyles: 'bg-grey-50 border-black-secondary',
    iconColor: 'text-green-primary',
  },
}

type Props = {
  children: string | React.ReactNode
  variant?: keyof typeof variants
  className?: string
  rightComponent?: React.ReactNode
}

export function Banner({
  variant = 'info',
  children,
  className,
  rightComponent,
}: Props): JSX.Element {
  return (
    <div
      role="banner"
      className={classNames(
        'flex items-center justify-between rounded-2xs border border-solid border-l-[4px] p-xs gap-xs',
        variants[variant].boxStyles,
        className,
      )}
    >
      <div className="flex items-center gap-xs">
        {variants[variant].icon && (
          <Icon
            data-testid="icon"
            icon={variants[variant].icon as IconProp}
            size="big"
            className={variants[variant].iconColor}
          />
        )}
        {typeof children === 'string' ? (
          <Text variant="content" bold>
            {children}
          </Text>
        ) : (
          children
        )}
      </div>
      {rightComponent && rightComponent}
    </div>
  )
}
