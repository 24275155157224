import { Outlet, useParams } from 'react-router-dom'
import { Icon, RoutingTabs, Spinner, Text } from 'src/components/ui'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { useEffect } from 'react'
import { useNavigationContext } from 'src/contexts/navigation'
import { useGateway } from 'gateway/api'
import { OpcUaConnectionMenu, OpcUaNavigation } from './opc-ua-connection'
import { ErrorDisplay } from 'pages/app'

export function OpcUaConnectionPage(): JSX.Element {
  const { siteId, gatewayId, connectionId } = useParams()
  if (!siteId || !gatewayId) throw new Error('siteId is not defined')

  const gatewayQuery = useGateway(siteId, gatewayId)
  const { setTitleComponent } = useNavigationContext()
  useEffect(() => {
    setTitleComponent(<OpcUaNavigation />)
    return () => setTitleComponent(null)
  }, [setTitleComponent])

  if (gatewayQuery.isLoading) return <Spinner />
  if (gatewayQuery.isError)
    return (
      <ErrorDisplay
        error={gatewayQuery.error}
        message="Failed to load gateway"
        action={gatewayQuery.refetch}
      />
    )

  const connection = gatewayQuery.data.opcConnections?.items?.find(
    c => c?.id === connectionId,
  )

  return (
    <div className="h-full p-[1em]">
      <div className="flex h-full flex-col gap-s">
        <div className="flex items-center justify-between rounded-2xs bg-white px-s py-m">
          <div className="flex items-center">
            <Icon
              icon={light('cloud-arrow-up')}
              className="mx-s size-[40px] text-grey-text"
            />
            <div className="ml-s flex flex-col gap-2xs">
              <Text variant="description">Connection name</Text>
              <Text
                bold
                className={classNames(!connection && 'text-grey-text')}
              >
                {connection?.name || connection?.id || 'Not specified'}
              </Text>
            </div>
            <div className="ml-xl flex flex-col gap-2xs">
              <Text variant="description">Status</Text>
              <Text
                bold
                className={classNames(
                  !(connection?.configurationState === 'COMPLETE') &&
                    'text-grey-text',
                )}
              >
                {connection?.configurationState === 'COMPLETE'
                  ? 'Configured'
                  : 'Not Configured'}
              </Text>
            </div>
            <div className="ml-xl flex flex-col gap-2xs">
              <Text variant="description">Gateway</Text>
              <Text bold>
                {gatewayQuery.data.id || 'Not specified'}
                <span className="font-normal">
                  {gatewayQuery.data.hostname &&
                    ` (${gatewayQuery.data.hostname})`}
                </span>
              </Text>
            </div>
          </div>
          {connection && (
            <OpcUaConnectionMenu
              configurationState={connection.configurationState}
              connectionId={connection.id}
            />
          )}
        </div>
        <div className="flex flex-col gap-s rounded-2xs bg-white p-s">
          <RoutingTabs
            tabs={[
              {
                title: 'OPC-UA Details',
                path: '',
                disabled:
                  !connectionId ||
                  !(connection?.configurationState === 'COMPLETE'),
              },
              {
                title: 'Subscriptions',
                path: 'subscriptions',
                disabled:
                  !connectionId ||
                  !(connection?.configurationState === 'COMPLETE'),
              },
              {
                title: 'Configuration',
                path: 'configuration',
                disabled:
                  !!connectionId &&
                  connection !== undefined &&
                  connection !== null &&
                  connection.configurationState === 'COMPLETE',
              },
            ]}
          />
          <Outlet />
        </div>
      </div>
    </div>
  )
}
