import { useModel } from 'models/api'
import { ModelAnomalyWithModelName } from 'src/types'
import { parseISO } from 'date-fns'
import { UseQueryResult } from '@tanstack/react-query'

export function useModelAnomalies(
  modelId: string,
): UseQueryResult<ModelAnomalyWithModelName[]> {
  return useModel(modelId, data => {
    if (!data || data.__typename !== 'AnomalyModel') {
      return []
    }

    return data.anomalies.map(anomaly => {
      return {
        ...anomaly,
        modelName: data.name,
        from: parseISO(anomaly.start).valueOf(),
        to: anomaly.end ? parseISO(anomaly.end).valueOf() : undefined,
        status: anomaly.state,
      }
    })
  })
}
