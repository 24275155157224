import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { AnimatePresence, motion } from 'framer-motion'
import { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon, Text } from 'src/components/ui'
import { encodeCharts } from 'src/contexts/charts/chartsEncoding'
import { ChartOptions, ChartType } from 'src/types/chartTypes'
import { useClickOutside } from 'src/utility'

type Props = {
  siteId: string
  tagName: string
}
export function NodeActions({ siteId, tagName }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)
  const navigate = useNavigate()

  useClickOutside(dropdownRef, () => {
    setIsOpen(false)
  })

  const handleViewInTrend = (): void => {
    const charts: ChartOptions[] = [
      {
        type: ChartType.TimeSeries,
        id: 0,
        data: [{ type: 'tag', id: tagName }],
      },
    ]

    navigate(`/site/${siteId}/trend?charts=${encodeCharts(charts)}`)
  }

  return (
    <div className="relative inline-block" ref={dropdownRef}>
      <Icon
        size="regular"
        icon={regular('ellipsis')}
        onClick={() => setIsOpen(!isOpen)}
      />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0, dur: 0.1 }}
            exit={{ opacity: 0, y: -10 }}
            transition={{
              opacity: { duration: 0.1 },
              y: { duration: 0.1 },
            }}
            className="absolute right-0 top-[80%] rounded-2xs bg-white shadow-dropdown"
          >
            <div
              className="flex cursor-pointer items-center gap-2xs p-xs transition hover:bg-gray-100"
              onClick={handleViewInTrend}
            >
              <Icon icon={light('chart-line-up')} size="regular" />
              <Text variant="description" bold>
                Show in Trend
              </Text>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
