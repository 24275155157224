import { useRef, useEffect } from 'react'
import Color from 'color'
import HighchartsReact from 'highcharts-react-official'
import Highcharts from 'highcharts/highstock'
import { TimeRange } from 'src/types'
import useTimeRange from 'src/contexts/timeRange'
import { theme } from 'tailwind.config'
import {
  useCursor,
  useFilterAreas,
  useChartWidth,
  useLoading,
  useZoom,
} from 'src/components/ui'
import { useFilter } from 'src/contexts/filter'
import { Legend } from 'pages/site/models/model'

interface Data {
  name: string
  color: string
  data: TimeRange[]
}

interface StatusTimelineProps {
  className?: string
  data?: Data[]
  showAnomalyLegend?: boolean
}

export function StatusTimeline({
  className,
  data = [],
  showAnomalyLegend = false,
}: StatusTimelineProps): JSX.Element {
  const ref = useRef<HighchartsReact.RefObject>(null)
  const { timeRange, setTimeRange } = useTimeRange()

  useZoom({ ref, ...timeRange })
  useLoading({ ref, loading: false, data })
  useCursor({ ref })
  useFilterAreas({ ref, desaturate: true })
  const { filter } = useFilter()

  const areas = useRef<Highcharts.SVGElement[]>()

  const width = useChartWidth(ref)

  useEffect(() => {
    if (ref.current) {
      const { xAxis, plotTop, plotHeight, renderer } = ref.current.chart
      const padding = 8
      areas.current = data.flatMap(({ color, data = [] }) =>
        data.map(({ from, to }) => {
          const begin = xAxis[0].toPixels(from, false)
          const stop = xAxis[0].toPixels(to, false)
          return renderer
            .rect(
              begin,
              plotTop + padding,
              stop - begin,
              plotHeight - 2 * padding,
            )
            .attr({ fill: color })
            .add()
        }),
      )
    }

    return () => {
      if (areas.current) {
        areas.current.forEach(area => area.destroy())
      }
    }
  }, [data, timeRange, width])

  const legend: Record<string, string> = {}
  for (const { name, color } of data) {
    legend[name] = color
  }
  if (filter) {
    legend['Filtered out'] = theme.colors.filter
  }
  if (showAnomalyLegend) {
    legend.Anomaly = Color(theme.colors.danger).alpha(0.3).toString()
  }

  return (
    <div className={className}>
      <div className="flex items-center justify-end">
        <Legend data={legend} />
      </div>
      <HighchartsReact
        ref={ref}
        highcharts={Highcharts}
        options={{
          chart: {
            events: {
              selection: (e: any) => {
                setTimeRange({
                  from: e.xAxis[0].min,
                  to: e.xAxis[0].max,
                })
                return false
              },
            },
            height: 60,
            backgroundColor: 'transparent',
            resetZoomButton: { theme: { display: 'none' } },
            style: { fontFamily: 'Roboto' },
            spacingTop: 0,
            spacingBottom: 0,
            spacingLeft: 0,
            spacingRight: 0,
            zoomType: 'x',
          },
          credits: { enabled: false },
          legend: {
            enabled: false,
          },
          loading: {
            style: {
              backgroundColor: 'transparent',
              cursor: 'default',
            },
          },
          series: [{ type: 'line', data: [] }],
          time: { useUTC: false },
          title: undefined,
          tooltip: { enabled: false },
          xAxis: {
            type: 'datetime',
            minRange: 1,
          },
          yAxis: { title: { text: '' } },
        }}
      />
    </div>
  )
}
