import { TableConfig } from '../table.types'
import { Text } from 'src/components/ui'

export const getSubscriptionTableConfig = (): TableConfig => {
  return {
    allowPaging: true,
    pageSize: 10,
    allowSorting: true,
    columns: [
      {
        field: 'name',
        headerName: 'Subscription Name',
        minWidth: 400,
        flex: 400,
        cellRenderer: ({ value }: any) => <Text bold>{value}</Text>,
      },
      {
        field: 'type',
        headerName: 'Source type',
        flex: 200,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
      {
        field: 'errors',
        headerName: 'Errors last 24 hours',
        flex: 200,
        cellRenderer: ({ value }: any) => <Text>{value} Errors</Text>,
      },
      {
        field: 'datapoints',
        headerName: 'Data last 24 hours',
        flex: 200,
        cellRenderer: ({ value }: any) => <Text>{value ?? 'N/A'}</Text>,
      },
      {
        field: 'data',
        headerName: 'Last 60 minutes',
        flex: 200,
        cellRenderer: ({ value }: any) => <Text>{value ?? 'N/A'}</Text>,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 200,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
    ],
  }
}
