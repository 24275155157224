import { useQuery, UseQueryResult } from '@tanstack/react-query'
import * as api from 'src/services'

export const GATEWAY_QUERY = 'gateway'
export const GATEWAYS_QUERY = 'gateways'

export function useGateway(
  factoryId: string,
  agentId: string,
): UseQueryResult<api.GqlGatewayFragment, Error> {
  return useQuery({
    queryKey: [GATEWAY_QUERY, factoryId, agentId],
    queryFn: async () => {
      const data = await api.fetchFactoryGateway(factoryId, agentId)
      if (!data) {
        throw new Error('Gateway not found')
      }
      return data
    },
  })
}

export function useGateways(
  factoryId: string,
): UseQueryResult<api.GqlGatewayFragment[], Error> {
  return useQuery({
    queryKey: [GATEWAYS_QUERY, factoryId],
    queryFn: async () => api.fetchFactoryGateways({ factory: factoryId }),
  })
}
