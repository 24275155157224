import {
  useQueries,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query'
import { useSite } from 'src/contexts/site'
import { TimeSeriesData } from 'src/types'
import { TimeRange } from 'src/types'
import { SeriesId } from 'src/types/chartTypes'
import { modelPredictionQuery, historicalForecastQuery } from 'models/api'
import { tagDataQuery } from 'tags/api'
import { modelAnomalyScoreQuery } from 'models/model'

function getTimeSeriesQuery(
  series: SeriesId,
  factory: string,
  timeRange: TimeRange,
): UseQueryOptions<TimeSeriesData[]> {
  const { id, type } = series
  switch (type) {
    case 'tag':
      return tagDataQuery({ id, factory, timeRange })
    case 'forecast':
      return historicalForecastQuery({
        id,
        offset: series.offset,
        factory,
        timeRange,
      })
    case 'prediction':
      return modelPredictionQuery({ id, timeRange })
    case 'spikeScore':
    case 'shortScore': {
      const transform = type === 'spikeScore' ? 'NONE' : 'SMOOTHED'
      return modelAnomalyScoreQuery({
        id,
        transform,
        errorScoreKind: 'ABSOLUTE',
        timeRange,
      })
    }
    default: {
      const discard: never = type
      throw new Error(`Unhandled series type: ${discard}`)
    }
  }
}

export function useTimeSeriesData(
  ids: SeriesId[],
  timeRange: TimeRange,
): UseQueryResult<TimeSeriesData[]>[] {
  const { id: factory } = useSite()
  const queries = ids.map(id => getTimeSeriesQuery(id, factory, timeRange))
  return useQueries({ queries })
}

export function timeSeriesRefetch({ from, to }: TimeRange): number | false {
  // if `now` is within the requested timeRange, refetch after a minute
  const now = Date.now()
  if (now > from && now < to) {
    return 60_000
  }
  // otherwise don't set a refetch interval
  return false
}
