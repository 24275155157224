import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, Text } from 'src/components/ui'
import { ChartType, getChartTypeLabel } from 'src/types/chartTypes'

type Props = {
  onButtonClick: (type: ChartType) => void
}
export function TrendLandingPage({ onButtonClick }: Props): JSX.Element {
  return (
    <div className="flex flex-1 flex-col items-center justify-center">
      <div className="flex h-[220px] w-[280px] flex-col items-center justify-center gap-xs rounded-2xs border border-dashed border-grey-100">
        <Text variant="title" bold className="mb-s">
          Add your first chart
        </Text>
        <Button
          title={`Add ${getChartTypeLabel(ChartType.TimeSeries)}`}
          variant="primary"
          icon={regular('chart-line')}
          className="w-[170px]"
          onClick={() => onButtonClick(ChartType.TimeSeries)}
        />
        <Button
          title={`Add ${getChartTypeLabel(ChartType.Scatter)}`}
          variant="primary"
          icon={regular('chart-scatter')}
          className="w-[170px]"
          onClick={() => onButtonClick(ChartType.Scatter)}
        />
      </div>
    </div>
  )
}
