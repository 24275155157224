import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { OnlyModelProps, SiteRole } from 'src/types'
import { useSite } from 'src/contexts/site'
import { Text, TextInput, Icon, Tooltip } from 'src/components/ui'
import { useRemoveStarMutation } from 'models/api'
import { ModelState } from '../../ModelState'
import { isModelEditing, modelValues } from '../modelOverview.state'

export function Name({ model }: OnlyModelProps): JSX.Element {
  const { viewerRole: role } = useSite()
  const removeStar = useRemoveStarMutation()

  return (
    <div className="flex flex-col gap-xs small:flex-row small:items-center">
      <div className="flex items-center gap-xs">
        {model.viewerHasStarred && (
          <Tooltip
            direction="bottom-start"
            className="!p-xs"
            render={() => (
              <Text variant="description">Remove from favorites</Text>
            )}
          >
            <Icon
              className="cursor-pointer text-black-secondary"
              size="regular"
              onClick={() =>
                removeStar.mutateAsync({
                  starrableId: model.id,
                })
              }
              icon={solid('star-sharp')}
            />
          </Tooltip>
        )}
        {isModelEditing.value && role !== SiteRole.READER ? (
          <TextInput
            value={modelValues.name.value}
            onChange={e => (modelValues.name.value = e.target.value)}
            containerStyles="!py-0"
            inputStyles="!text-2xl large:min-w-[420px]"
          />
        ) : (
          <Text variant="title" bold className="!text-2xl">
            {model.name}
          </Text>
        )}
      </div>
      <div>
        <ModelState state={model.state} />
      </div>
    </div>
  )
}
