import { SizeProp } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome'

type Size = 'xsmall' | 'small' | 'regular' | 'big'

const sizes: Record<Size, SizeProp> = {
  xsmall: 'xs',
  small: 'sm',
  regular: '1x',
  big: 'xl',
}

interface Props extends Omit<FontAwesomeIconProps, 'size'> {
  size?: Size
}

export default function Icon({
  icon,
  size = 'small',
  ...props
}: Props): JSX.Element {
  return <FontAwesomeIcon icon={icon} size={sizes[size]} {...props} />
}
