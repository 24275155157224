import { useEffect, useMemo } from 'react'
import { useForecastModels } from 'models/api'
import { isRunning } from 'models/model/model.utils'
import { triggerChartRedraw, useUrlQuery, getArray } from 'src/utility'
import { DynamicTable, Text, Spinner } from 'src/components/ui'
import { getForecastModelsConfig } from './forecastsTable.config'

export function ForecastsTab(): JSX.Element {
  const { data: forecastModels = [], isLoading } = useForecastModels()
  const models = forecastModels
    .filter(({ state }: any) => isRunning(state))
    .map(m => {
      return {
        ...m,
        tag: {
          ...m.tag,
          displayTagName: m.tag.displayName
            ? m.tag.displayName
            : m.tag.tagName || '',
        },
      }
    })

  const { query, update } = useUrlQuery()
  const activeModels = useMemo(() => getArray(query.models), [query.models])

  const addModel = (modelId: string): void =>
    update({
      models: [...activeModels, modelId],
    })

  const removeModel = (modelId: string): void =>
    update({
      models: activeModels.filter(id => id !== modelId),
    })

  useEffect(() => {
    triggerChartRedraw()
  }, [activeModels])

  const toggleRow = ({ id }: any): any => {
    if (activeModels.find(el => el === id)) {
      removeModel(id)
    } else {
      addModel(id)
    }
  }

  const actions = {
    toggleRow,
  }

  return (
    <div className="flex h-full flex-col gap-[0.5em] pb-xs">
      <Text>Select forecasts to show</Text>
      {isLoading ? (
        <Spinner />
      ) : (
        <DynamicTable
          id="ForecastModels"
          data={models}
          config={getForecastModelsConfig(actions)}
          selectedRows={activeModels}
        />
      )}
    </div>
  )
}
