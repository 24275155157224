import { Text, TimePickerNavigation, TimeSeriesChart } from 'src/components/ui'
import { TimeSeriesData } from 'src/types'
import { TagDto } from 'src/services'
import { useTagData, useTag } from 'tags/api'

type Props = {
  tagName: string
}

interface TrendChartProps {
  tag: TagDto
  tagData: TimeSeriesData[]
  isPending: boolean
}

const TrendChart = (props: TrendChartProps): JSX.Element => {
  return (
    <TimeSeriesChart
      {...props}
      data={[{ ...props.tag, data: props.tagData }]}
      isPending={props.isPending}
      tooltip
    />
  )
}

export function TagData({ tagName }: Props): JSX.Element {
  const { data: tag, isLoading } = useTag(tagName)
  const tagData = useTagData({ id: tagName })

  if (isLoading) return <></>

  return (
    <div className="flex flex-col gap-s rounded-2xs border border-solid border-grey-50 bg-white p-[1em] transition-all hover:border-grey-100">
      <div className="flex justify-between">
        <Text variant="title" bold>
          Tag data
        </Text>
        <TimePickerNavigation />
      </div>
      {tag && (
        <TrendChart
          tag={tag}
          tagData={tagData.data ?? []}
          isPending={isLoading || tagData.isLoading}
        />
      )}
    </div>
  )
}
