import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Button,
  DynamicTable,
  Text,
  getSubscriptionTableConfig,
} from 'src/components/ui'
import { useGateways } from 'gateway/api'
import { GqlOpcConnectionFragment } from 'src/services'
import { CreateDataSubscriptionModal } from './data-subscriptions'

export function DataSubscriptions(): JSX.Element {
  const { orgId, siteId } = useParams()
  const [createOpened, setCreateOpened] = useState(false)
  const { data: gateways } = useGateways(siteId ?? '')
  const navigate = useNavigate()

  const actions = {
    selectRow: (data: GqlOpcConnectionFragment) => {
      // TODO: This is done for opc connections and should be changed to data subscriptions when we have them
      navigate(`/settings/orgs/${orgId}/sites/${siteId}/opc-ua/${data.id}`)
    },
  }

  return (
    <>
      <div className="flex flex-1 flex-col gap-s">
        <div className="mt-m flex items-center justify-between">
          <Text variant="description">Configured Data Subcriptions</Text>
          <Button
            className="!w-auto"
            variant="primary"
            title="Create Data Subscriptions"
            onClick={() => setCreateOpened(true)}
          />
        </div>

        <DynamicTable
          // TODO: This should be changed to data subscriptions
          data={[]}
          actions={actions}
          config={getSubscriptionTableConfig()}
          id="subscriptions"
        />
      </div>
      <CreateDataSubscriptionModal
        gateways={gateways ?? []}
        isOpen={createOpened}
        setIsOpen={setCreateOpened}
        handleSubmit={() => null}
      />
    </>
  )
}
