import { GqlOpcSecurityMode, GqlOpcSecurityPolicy } from 'src/services'

export type SecurityOptions = {
  certificates: Certificate[]
  securityTypes: SecurityType[]
}

export type Certificate = {
  serialNumber: string
  thumbprint: string
  value: string
  label: string
}

export type SecurityType = {
  label: string
  value: string
  policies: SecurityPolicy[]
}

export type SecurityPolicy = {
  label: string
  value: string
  authenticationMethods: {
    anonymous: boolean
    username: boolean
  }
}

export type OpcUaConnectionConfiguration = {
  connectionId?: string
  gatewayId: string
  serverEndpoint: string
  connectionName: string
  connectionState: ConnectionStatus
  connectionVerifyText?: string
  detailsCompleted: boolean
  securityType?: GqlOpcSecurityMode
  securityPolicy?: GqlOpcSecurityPolicy
  securityCertificate?: string
  securityAnonymous?: boolean
  userName?: string
  password?: string
  securityOptions?: SecurityOptions
  securityConnectionState?: ConnectionStatus
  securityResponseText?: string
  securityCompleted?: boolean
  connectionConfigured?: boolean
}

export enum ConnectionStatus {
  UNVERIFIED = 'UNVERIFIED',
  VERIFYING = 'VERIFYING',
  VERIFIED = 'VERIFIED',
  FAILED = 'FAILED',
}

export enum NodeType {
  Folder = 'folder',
  Tag = 'tag',
}

export enum StatusCode {
  Good = 'Good',
  Bad = 'Bad',
}

export type ErrorNode = {
  id: string
  reason: string
}
