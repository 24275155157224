import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useViewerQuery } from 'account/api'
import { GqlFetchCustomerQuery, GqlMeFragment } from 'src/services'
import {
  isDefined,
  Organization,
  OrganizationRole,
  organizationRoleFromGql,
  OrganizationStatus,
  OrganizationUser,
  Site,
  siteRoleFromGql,
} from 'src/types'
import * as api from 'src/services'

export const ORGANIZATION_QUERY = 'organization'

type ApiOrganizations = NonNullable<
  NonNullable<GqlMeFragment['customers']>['items']
>
type ApiOrganizationUsers = NonNullable<
  GqlFetchCustomerQuery['customer']
>['users']
type ApiCustomer = GqlMeFragment['customer']

function toOrganizations(
  organizations: ApiOrganizations,
  homeOrganization: ApiCustomer,
): Organization[] {
  return organizations.filter(isDefined).map(org => ({
    id: org.id,
    name: org.name,
    viewerRole: org.viewerRole
      ? organizationRoleFromGql(org.viewerRole)
      : OrganizationRole.MEMBER,
    numOfSites: org.factoryCount,
    isHome: org.id === homeOrganization?.id,
    userCount: org.userCount ?? 0,
  }))
}

function toOrganization(data: GqlFetchCustomerQuery): Organization | null {
  const org = data.customer
  if (!org) {
    return null
  }

  return {
    id: org.id,
    name: org.name,
    viewerRole: org.viewerRole
      ? organizationRoleFromGql(org.viewerRole)
      : OrganizationRole.MEMBER,
    numOfSites: org.factoryCount,
    isHome: org.id === data.me.customer?.id,
    userCount:
      org.users?.edges?.filter(u => u?.node?.status === 'ENABLED').length ?? 0,
  }
}

export function useOrganizations(): UseQueryResult<Organization[]> {
  return useViewerQuery(user =>
    toOrganizations(user.customers?.items ?? [], user.customer),
  )
}

function useOrganizationQuery<T = api.GqlFetchCustomerQuery>(
  organizationId: string,
  select?: (data: api.GqlFetchCustomerQuery) => T,
): UseQueryResult<T, Error> {
  return useQuery({
    queryKey: [ORGANIZATION_QUERY, organizationId],
    queryFn: async () => api.fetchOrganization({ id: organizationId }),
    select,
  })
}

export function useOrganization(
  organizationId: string,
): UseQueryResult<Organization | null, Error> {
  return useOrganizationQuery(organizationId, toOrganization)
}

function toOrgUsers(
  apiUsers: ApiOrganizationUsers,
  orgId: string,
): OrganizationUser[] {
  return (
    apiUsers?.edges
      ?.map(user => {
        if (user?.node?.status === 'ENABLED') {
          return {
            id: user.node.id,
            name: user.node.name,
            email: user.node.email ?? '',
            role: organizationRoleFromGql(user.role),
            status: OrganizationStatus.ACTIVE,
            userTag: user.node.customer?.id === orgId ? 'Home' : 'Verified',
            homeOrganization: {
              id: user.node.customer?.id ?? '',
              name: user.node.customer?.name ?? '',
            },
          }
        }
        return null
      })
      .filter(isDefined) ?? []
  )
}

export function useOrganizationUsers(
  organizationId: string,
): UseQueryResult<OrganizationUser[], Error> {
  return useOrganizationQuery(organizationId, org => {
    if (org.customer?.users)
      return toOrgUsers(org.customer.users, organizationId)
    return []
  })
}

export function useOrganizationSites({
  orgId,
}: {
  orgId: string
}): UseQueryResult<Site[], Error> {
  return useOrganizationQuery(orgId, data => {
    const org = data.customer
    if (!org) {
      return []
    }
    const sites = org.factories?.items ?? []
    return sites.filter(isDefined).map(site => ({
      id: site.id,
      name: site.name,
      orgName: org.name,
      orgId: org.id,
      viewerRole: site.viewerRole
        ? siteRoleFromGql(site.viewerRole)
        : undefined,
      numOfGateways: site.agents?.items?.length ?? 0,
      numOfUsers: site.userCount,
    }))
  })
}
