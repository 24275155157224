import { Text } from 'src/components/ui'
import { getBaseFilterParams } from '../table.utils'
import { TableConfig } from '../table.types'

type Props = {
  canDrag: boolean
}
export const getAssetTagsTableConfig = ({ canDrag }: Props): TableConfig => {
  return {
    pageSize: 25,
    allowPaging: true,
    allowSorting: false,
    pageSizes: [5, 10, 25, 100],
    disallowSelection: false,
    columns: [
      {
        headerName: 'Tag',
        dndSource: canDrag,
        filterParams: getBaseFilterParams(true),
        valueGetter: ({ data: { displayName, tagName } }: any) =>
          displayName ?? tagName,
        flex: 140,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
      {
        field: 'parentName',
        headerName: 'Parent Asset',
        flex: 140,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
      {
        field: 'label',
        headerName: 'Label',
        flex: 140,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
      {
        field: 'description',
        headerName: 'Description',
        filterParams: getBaseFilterParams(true),
        flex: 140,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
      {
        field: 'engUnit',
        headerName: 'Unit',
        flex: 120,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
    ],
  }
}
