import { RadioGroup } from '@headlessui/react'
import classNames from 'classnames'
import { Text } from 'src/components/ui'

type Option = {
  label: string
  value: string
  disabled?: boolean
}

type Props = {
  selected: Option
  setSelected: (value: Option) => void
  options: Option[]
  disabled?: boolean
}

export function RadioGroupInput({
  selected,
  setSelected,
  options,
  disabled,
}: Props): JSX.Element {
  return (
    <RadioGroup value={selected} onChange={setSelected} disabled={disabled}>
      <div className="flex items-center gap-m">
        {options.map(o => (
          <RadioGroup.Option
            key={o.value}
            value={o}
            disabled={o.disabled}
            className={classNames(
              'flex items-center gap-xs outline-none',
              disabled || o.disabled
                ? 'opacity-50 cursor-default'
                : 'cursor-pointer',
            )}
            data-testid={o.value}
          >
            {({ checked }) => (
              <>
                <div
                  className={classNames(
                    'flex aspect-square w-[16px] items-center justify-center rounded-full border-solid',
                    checked
                      ? 'border-green-primary border-[2px]'
                      : 'border-black-primary border',
                  )}
                >
                  {checked && (
                    <div className="aspect-square w-xs rounded-full bg-green-primary" />
                  )}
                </div>
                <RadioGroup.Label
                  as={Text}
                  variant="description"
                  className="whitespace-nowrap"
                  bold
                >
                  {o.label}
                </RadioGroup.Label>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}
