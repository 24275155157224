import { useNavigate } from 'react-router-dom'
import { Button, Text } from 'src/components/ui'

export function TrendBetaRedirectPage(): JSX.Element {
  const navigate = useNavigate()
  return (
    <div className="flex size-full flex-col items-center justify-center">
      <div className="flex w-[400px] flex-col items-center justify-center gap-xs rounded-2xs border border-solid border-grey-100 bg-white px-m py-xl text-center">
        <Text variant="title" bold className="mb-s">
          Trend beta has now been released
        </Text>
        <Text>
          Please update your bookmark to the new trend page. Links using trend
          beta will stop working on the 12.12.2023
        </Text>
        <Button
          title={`Redirect`}
          variant="primary"
          className="mt-m w-[170px]"
          onClick={() =>
            navigate(
              `${window.location.pathname.substring(
                0,
                window.location.pathname.lastIndexOf('/'),
              )}/trend${window.location.search}`,
            )
          }
        />
      </div>
    </div>
  )
}
