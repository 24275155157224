import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import classNames from 'classnames'
import { useRef, useState } from 'react'
import { Button, Icon, Text } from 'src/components/ui'
import { useClickOutside } from 'src/utility'

type MenuItem = {
  icon: IconProp
  label: string
  onClick: () => void
  isVisible?: boolean
  isDisabled?: boolean
  hasBorder?: boolean
}

type Props = {
  menuItems: MenuItem[]
}

export function TrendMenu({ menuItems }: Props): JSX.Element {
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement>(null)

  useClickOutside(menuRef, () => {
    if (isOpen) {
      setIsOpen(false)
    }
  })

  return (
    <div className="relative" ref={menuRef}>
      <Button
        variant="icon"
        icon={regular('ellipsis')}
        onClick={() => setIsOpen(!isOpen)}
      />
      {isOpen && (
        <div className="absolute right-0 top-[calc(100%+4px)] z-20 flex min-w-[100px] flex-col whitespace-nowrap rounded-2xs border border-solid border-grey-100 bg-white drop-shadow-dropdown">
          {menuItems.map(
            ({
              icon,
              label,
              onClick,
              isVisible = true,
              isDisabled = false,
              hasBorder = false,
            }) =>
              isVisible && (
                <div
                  key={label}
                  className={classNames(
                    'flex items-center gap-xs p-xs transition-all',
                    isDisabled
                      ? 'opacity-50 cursor-default'
                      : 'hover:bg-grey-50 cursor-pointer',
                    hasBorder &&
                      'border-0 border-t border-solid border-grey-100',
                  )}
                  onClick={() => {
                    setIsOpen(false)
                    onClick()
                  }}
                >
                  <Icon icon={icon} className="w-[15px]" />
                  <Text bold variant="description">
                    {label}
                  </Text>
                </div>
              ),
          )}
        </div>
      )}
    </div>
  )
}
