import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useLocation, useNavigate } from 'react-router-dom'
import { Icon, Text } from 'src/components/ui'
import { useGateways } from 'gateway/api'

type Props = {
  browse?: boolean
}

export function OpcUaNavigation({ browse }: Props): JSX.Element {
  const { pathname } = useLocation()
  const pathSplit = pathname.split('/')
  const navigate = useNavigate()

  const orgId = pathSplit[pathSplit.indexOf('orgs') + 1] || undefined
  const siteId = pathSplit[pathSplit.indexOf('sites') + 1] || undefined
  const gatewayId = pathSplit[pathSplit.indexOf('gateways') + 1] || undefined
  const opcConnectionId =
    pathSplit[pathSplit.indexOf('opc-ua') + 1] || undefined
  const { data: gateways } = useGateways(siteId ?? '')

  const baseRoute = `/settings/orgs/${orgId}/sites/${siteId}/gateways/${gatewayId}`
  const backUrl = browse ? `${baseRoute}/opc-ua/${opcConnectionId}` : baseRoute

  const selectedGateway = gateways?.find(gateway => gateway.id === gatewayId)
  const selectedOpcConnection = selectedGateway?.opcConnections?.items?.find(
    c => c?.id === opcConnectionId,
  )

  const title = browse
    ? `OPC-UA Connections | ${
        selectedOpcConnection?.name || selectedOpcConnection?.id || 'Connection'
      } |`
    : `${selectedGateway?.hostname ?? 'Gateway name'} | OPC-UA Connections |`

  return (
    <div className="flex items-center gap-xs">
      <Icon
        size="big"
        className="cursor-pointer"
        icon={regular('circle-chevron-left')}
        onClick={() => navigate(backUrl)}
      />
      <div className="flex items-center">
        <Text variant="content-rubik" bold className="!mt-3xs inline">
          {title}
        </Text>{' '}
        <Text variant="title" bold className="inline">
          &nbsp;
          {browse
            ? 'Browse Hierarchy'
            : selectedOpcConnection
            ? selectedOpcConnection.name
            : 'Create OPC-UA Connection'}
        </Text>
      </div>
    </div>
  )
}
