import { useTags } from 'tags/api'
import { Button } from 'src/components/ui'
import { Record } from '../steps'
import { useNavigate } from 'react-router-dom'
import { useCreateModelMutation, useModelTypes } from 'models/api'
import { CreateModel } from 'src/services'
import { isDefined } from 'src/types'
import { ModelTypes } from 'src/types'

function modelTypenameToModelTypes(modelTypename?: ModelTypes | null): string {
  if (modelTypename === 'AnomalyModel') return 'Anomaly Detection'
  if (modelTypename === 'ForecastModel') return 'Forecast'
  return ''
}

interface CreateModelButtonProps {
  state: Record
  disabled: boolean
}

export function CreateModelButton({
  state,
  disabled,
}: CreateModelButtonProps): JSX.Element {
  const { data: tags = [] } = useTags()
  const navigate = useNavigate()
  const modelTypesQuery = useModelTypes()
  const createModel = useCreateModelMutation()

  const modelTypes = modelTypesQuery.data ?? []

  async function makeModel(): Promise<void> {
    const modelType = modelTypes.find(
      ({ name }) => modelTypenameToModelTypes(state.modelType) === name,
    )
    if (!modelType) {
      console.error(`Could not find model type: ${state.modelType}`)
      return
    }
    const method = modelType.methods.find(({ name }) =>
      state.modelType === 'ForecastModel'
        ? name === 'Forecast 1H'
        : name === 'Nowcast',
    )
    if (!method) {
      console.error(`Could not find model method: ${state.modelType}`)
      return
    }
    const tag = tags.find(tag => tag.tagName === state.outputTag)
    if (!tag || !tags) return
    const model: Omit<CreateModel, 'factory'> = {
      name: state.name ?? '',
      typeId: modelType.id,
      methodId: method.id,
      description: state.description ?? '',
      tagId: tag.tagNodeId,
      inputTagIds:
        (state.inputTags &&
          state.inputTags
            .map(
              tagName => tags.find(tag => tag.tagName === tagName)?.tagNodeId,
            )
            .filter(isDefined)) ??
        [],
      trainingPeriod: 'P1Y',
    }

    await createModel.mutateAsync(model, {
      onSuccess: data => {
        navigate(`../${data.id}`, { state: { edit: true } })
      },
    })
  }

  return (
    <Button
      variant="primary"
      title="Create model"
      disabled={disabled}
      onClick={makeModel}
      isPending={createModel.isLoading}
    />
  )
}
