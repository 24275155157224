import { HomeOrganization } from 'src/types'

export function HomeOrgField({
  homeOrganization,
  orgId,
}: {
  homeOrganization: HomeOrganization
  orgId: string
}): JSX.Element {
  return (
    <div>
      <div className="relative inline-flex h-l flex-wrap items-center justify-start ">
        <span
          className={`${
            homeOrganization.id === orgId
              ? 'border-green-primary bg-green-20'
              : 'border-grey-text bg-grey-background-v2'
          } sticky z-[2] rounded-[2px] border-2 border-r-0 border-solid px-xs py-0 text-description font-500 leading-code`}
        >
          {homeOrganization.name}
        </span>
        <div
          className={`${
            homeOrganization.id === orgId
              ? 'border-green-primary bg-green-20'
              : 'border-grey-text bg-grey-background-v2'
          } absolute -right-xs top-1/2 z-[1] size-[19px] -translate-y-1/2 rotate-45 rounded-2xs border-b-0 border-l-0 border-r-2 border-t-2 border-solid`}
        />
      </div>
    </div>
  )
}
