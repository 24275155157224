import { isDefined, TimeRange } from 'src/types'
import { GqlThresholdKind } from 'src/services'
import { theme } from 'tailwind.config'
import { StatusTimeline } from './StatusTimeline'
import { Data } from '../anomalyThresholds.types'

type Acc = {
  list: TimeRange[]
  from?: number
}
type Condition = (value: number) => boolean

const ranges = (data: Data[], condition: Condition): TimeRange[] =>
  data.reduce<Acc>(
    ({ list, from }, [time, value], index, array) => {
      if (index === array.length - 1 && from !== undefined) {
        return {
          list: [...list, { from, to: time }],
          from: undefined,
        }
      }
      if (isDefined(value) && condition(value)) {
        if (from !== undefined) {
          return { list, from }
        }
        return { list, from: time }
      }
      if (from !== undefined) {
        return {
          list: [...list, { from, to: time }],
          from: undefined,
        }
      }
      return { list, from }
    },
    { list: [] },
  ).list

interface AnomalyStatusTimelineProps {
  data?: Data[]
  threshold: number
  thresholdType?: GqlThresholdKind
  showAnomalyLegend?: boolean
}

export function AnomalyStatusTimeline({
  data = [],
  threshold,
  thresholdType,
  showAnomalyLegend = false,
}: AnomalyStatusTimelineProps): JSX.Element {
  const error = ranges(data, value =>
    thresholdType === 'UPPER' ? value > threshold : value < threshold,
  )
  const ok = ranges(data, value =>
    thresholdType === 'UPPER' ? value <= threshold : value >= threshold,
  )
  const {
    colors: { primary, danger },
  } = theme

  return (
    <StatusTimeline
      className="ml-[40px]"
      data={[
        {
          name: 'Non-anomalous',
          color: primary,
          data: ok,
        },
        {
          name: 'Anomalous',
          color: danger,
          data: error,
        },
      ]}
      showAnomalyLegend={showAnomalyLegend}
    />
  )
}
