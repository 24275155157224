import classNames from 'classnames'
import { Text } from 'src/components/ui'

type Step = {
  label: string
  state: 'default' | 'active' | 'completed'
}

type Props = {
  steps: Step[]
  onClick: (index: number) => void
}

export function Stepper({ steps, onClick }: Props): JSX.Element {
  return (
    <div className="relative flex gap-[54px]">
      {steps.map((step, index) => (
        <>
          <div
            className="group relative flex w-[40px] cursor-pointer flex-col gap-xs"
            onClick={() => onClick(index)}
          >
            {index !== 0 && (
              <div className="absolute left-[-27px] top-[19px] h-3xs w-[27px] bg-grey-100" />
            )}
            <div
              className={classNames(
                'flex aspect-square w-[40px] items-center justify-center rounded-full border-[2px] border-solid group-hover:bg-green-secondary group-hover:border-green-hover transition duration-300 group-active:border-green-active group-active:bg-grey-background',
                step.state === 'default' &&
                  'border-grey-50 bg-grey-50 text-grey-text',
                step.state === 'active' && 'border-green-primary bg-white',
                step.state === 'completed' &&
                  'border-green-secondary bg-green-secondary',
              )}
              key={index}
            >
              <Text variant="title" className="!leading-[21px]">
                {index + 1}
              </Text>
            </div>
            {index !== steps.length - 1 && (
              <div className="absolute right-[-27px] top-[19px] h-3xs w-[27px] bg-grey-100" />
            )}
            <Text
              variant="description"
              bold
              className={classNames(
                'w-[90px] overflow-clip translate-x-[-25px] text-center group-hover:text-black-primary transition duration-300 group-active:text-black-secondary',
                step.state === 'default' && 'text-grey-text',
                step.state === 'completed' && 'text-green-hover',
              )}
            >
              {step.label}
            </Text>
          </div>
        </>
      ))}
    </div>
  )
}
