import { humanDateTime, timeAgo } from 'src/utility/time'
import { Text } from 'src/components/ui'

interface TimeProps {
  time: number
}

export function AnomalyTime({ time }: TimeProps): JSX.Element {
  return (
    <>
      <Text>{humanDateTime(time)}</Text>
      <Text className="text-grey-text">{timeAgo(time)}</Text>
    </>
  )
}
