import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useLocation, useNavigate } from 'react-router-dom'
import { useMySites } from 'orgs-sites/site/api'
import { Icon, Text } from 'src/components/ui'
import { useGateways } from 'gateway/api'

export function GatewayDetailsNavigation(): JSX.Element {
  const { pathname } = useLocation()
  const pathSplit = pathname.split('/')
  const navigate = useNavigate()
  const sitesQuery = useMySites()

  const orgId = pathSplit[pathSplit.indexOf('orgs') + 1] || undefined
  const siteId = pathSplit[pathSplit.indexOf('sites') + 1] || undefined
  const gatewayId = pathSplit[pathSplit.indexOf('gateways') + 1] || undefined
  const { data: gateways } = useGateways(siteId ?? '')

  const backUrl = `/settings/orgs/${orgId}/sites/${siteId}`

  const selectedGateway = gateways?.find(gateway => gateway.id === gatewayId)
  const site = sitesQuery.data?.find(site => site.id === siteId)

  return (
    <div className="flex items-center gap-xs">
      <Icon
        size="big"
        className="cursor-pointer"
        icon={regular('circle-chevron-left')}
        onClick={() => navigate(backUrl)}
      />
      <div className="flex items-center">
        <Text variant="content-rubik" bold className="!mt-3xs inline">
          {`${site?.name ?? 'Site'} | Gateways |`}
        </Text>{' '}
        <Text variant="title" bold className="inline">
          &nbsp;
          {selectedGateway?.hostname ?? 'Gateway'}
        </Text>
      </div>
    </div>
  )
}
