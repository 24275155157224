import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import MenuWrapper from 'react-popper-tooltip'
import { useTheme } from 'styled-components'
import { useState } from 'react'
import { debounce } from 'lodash'
import queryString from 'query-string'
import {
  Button,
  Checkbox,
  Icon,
  NumberInput,
  Text,
  SelectInput,
} from 'src/components/ui'
import classNames from 'classnames'
import { useSite } from 'src/contexts/site'
import useTimeRange from 'src/contexts/timeRange'
import { zIndex } from 'src/utility/constants/StyleConstants'
import { useModel } from 'models/api'
import { ChartOptions, SeriesOptions } from 'src/types/chartTypes'
import { IfLabsAccess } from 'pages/site/models'
import { ChartData } from '../../useChartData'

const forecastOptions = (method: string | undefined): number[] => {
  switch (method) {
    case 'Forecast 1H':
      return [15, 30, 45, 60]
    case 'Forecast 2H':
      return [30, 60, 90, 120]
    case 'Forecast 4H':
      return [60, 120, 180, 240]
    default:
      return []
  }
}

interface OffsetSelectorProps {
  id: any
  offset: number
  setOptions: (options: Partial<SeriesOptions>) => void
}

const OffsetSelector = ({
  id,
  offset,
  setOptions,
}: OffsetSelectorProps): JSX.Element => {
  const { data: model } = useModel(id)

  return (
    <div className="flex items-center justify-between gap-s">
      <label>
        <Text variant="small" bold className="whitespace-nowrap">
          Offset (in minutes)
        </Text>
      </label>
      <div className="w-full">
        <SelectInput
          value={{ value: offset.toString(), label: offset.toString() }}
          options={forecastOptions(model?.method?.name).map(value => ({
            label: value.toString(),
            value: value.toString(),
          }))}
          onChange={value => value && setOptions({ offset: parseInt(value) })}
        />
      </div>
    </div>
  )
}

interface MenuProps {
  chart: ChartOptions
  data: ChartData[]
  setOptions?: (index: number, options: Partial<SeriesOptions>) => void
  setChart?: (options: ChartOptions) => void
  isModal?: boolean
}

export default function GraphSettings({
  chart,
  data,
  setOptions,
  setChart,
  isModal,
}: MenuProps): JSX.Element {
  const [selected, setSelected] = useState(chart.commonY ?? false)
  const [openedIndexes, setOpenedIndexes] = useState<number[]>([])
  const [minMaxError, setMinMaxError] = useState<
    { index: number; message: string; min: boolean }[]
  >([])
  const { rootLink } = useSite()
  const { timeRange } = useTimeRange() || {}

  const theme = useTheme()

  const debouncedSetOptions = debounce(
    (index, options) => setOptions && setOptions(index, options),
    500,
  )

  const debouncedSetChart = debounce(
    options => setChart && setChart(options),
    500,
  )

  return (
    <MenuWrapper
      placement="left"
      trigger="click"
      tooltip={({ tooltipRef: ref, getTooltipProps }) => (
        <div
          className="max-h-[350px] overflow-y-auto rounded-2xs border border-solid border-grey-100 bg-white px-m py-xs shadow-lg"
          {...getTooltipProps({
            ref,
            style: {
              zIndex: isModal ? zIndex.modalLegendMenu : zIndex.trendLegendMenu,
            },
          })}
        >
          <Text variant="description" bold>
            Chart Settings
          </Text>
          <div className="flex items-center gap-2xs py-xs">
            <Checkbox
              value={selected}
              onChange={val => {
                setSelected(val)
                if (!setChart) return
                setChart({ ...chart, commonY: val })
              }}
            />
            <Text variant="small" bold>
              Common Y Axis
            </Text>
          </div>
          {selected && (
            <div className="flex items-center justify-between py-xs">
              <div className="flex w-[110px] items-center gap-xs">
                <Text variant="small" bold>
                  Min:
                </Text>
                <NumberInput
                  value={chart.min}
                  allowUndefined
                  onChange={val => {
                    if (setChart) debouncedSetChart({ ...chart, min: val })
                  }}
                  placeholder="Auto"
                />
              </div>

              <div className="flex w-[110px] items-center gap-xs">
                <Text variant="small" bold>
                  Max:
                </Text>
                <NumberInput
                  value={chart.max}
                  allowUndefined
                  onChange={val => {
                    if (setChart) debouncedSetChart({ ...chart, max: val })
                  }}
                  placeholder="Auto"
                />
              </div>
            </div>
          )}
          {data.map((data, index) => {
            const colorIndex = chart.data[index].colorIndex ?? chart.id + index
            const color =
              theme.colors.chart[colorIndex % theme.colors.chart.length]
            return (
              <div
                key={index}
                className="w-[245px] border-0 border-t border-solid border-grey-100 py-xs"
              >
                <div
                  className="flex cursor-pointer items-center justify-between gap-l"
                  onClick={() =>
                    openedIndexes.includes(index)
                      ? setOpenedIndexes(openedIndexes.filter(i => i !== index))
                      : setOpenedIndexes([...openedIndexes, index])
                  }
                >
                  <div className="flex items-center gap-2xs">
                    <div
                      className="aspect-square w-[13px] rounded-full"
                      style={{ backgroundColor: color }}
                    />
                    <Text
                      variant="small"
                      bold
                      className="max-w-[150px] overflow-hidden text-ellipsis"
                    >
                      {chart.data[index].type === 'tag'
                        ? data.displayName || data.name || chart.data[index].id
                        : data.name || chart.data[index].id}
                    </Text>
                  </div>
                  <Icon
                    icon={light('chevron-down')}
                    className={classNames(
                      'transition-all',
                      openedIndexes.includes(index) && 'rotate-180',
                    )}
                  />
                </div>
                {openedIndexes.includes(index) && (
                  <div className="flex flex-col gap-xs">
                    <div className="flex items-center gap-2xs py-xs">
                      {theme.colors.chart.map((color, i) => (
                        <div
                          key={i}
                          onClick={e => {
                            e.preventDefault()
                            return (
                              setOptions && setOptions(index, { colorIndex: i })
                            )
                          }}
                          className={classNames(
                            'aspect-square cursor-pointer rounded-full relative',
                            colorIndex === i
                              ? 'w-[8px] border border-solid m-[3px]'
                              : 'w-[14px]',
                          )}
                          style={{ backgroundColor: color, borderColor: color }}
                        >
                          <div
                            className={`absolute left-[50%] top-[50%] aspect-square w-[14px] translate-x-[-50%] translate-y-[-50%] rounded-full border border-solid ${
                              colorIndex === i ? 'block' : 'hidden'
                            }`}
                            style={{ borderColor: color }}
                          />
                        </div>
                      ))}
                    </div>
                    <div className="flex items-center gap-xs">
                      <Checkbox
                        value={!chart.data[index].disableRange}
                        onChange={checked =>
                          setOptions &&
                          setOptions(index, { disableRange: !checked })
                        }
                      />
                      <Text variant="small" bold>
                        {['forecast', 'prediction'].includes(
                          chart.data[index].type,
                        )
                          ? 'Display prediction interval'
                          : 'Display min/max range'}
                      </Text>
                    </div>
                    {chart.data[index].type === 'forecast' && (
                      <OffsetSelector
                        id={chart.data[index].id}
                        offset={
                          (chart.data[index] as { offset: number }).offset
                        }
                        setOptions={o => setOptions && setOptions(index, o)}
                      />
                    )}

                    <div className="flex items-center justify-between">
                      <div className="flex w-[100px] items-center gap-xs">
                        <Text variant="small" bold>
                          Min:
                        </Text>
                        <NumberInput
                          error={minMaxError.find(e => e.index === index)?.min}
                          value={chart.data[index].min}
                          allowUndefined
                          disabled={!!chart.min || !!chart.commonY}
                          onChange={val => {
                            const seriesOptions = chart.data[index]
                            if (
                              val &&
                              seriesOptions &&
                              seriesOptions.max &&
                              val > seriesOptions.max
                            ) {
                              setMinMaxError(prev => [
                                ...prev,
                                {
                                  index,
                                  message:
                                    'Min value must be less than max value',
                                  min: true,
                                },
                              ])
                              return null
                            }
                            setMinMaxError(prev =>
                              prev.filter(e => e.index !== index),
                            )
                            return debouncedSetOptions(index, { min: val })
                          }}
                          placeholder="Auto"
                        />
                      </div>

                      <div className="flex w-[100px] items-center gap-xs">
                        <Text variant="small" bold>
                          Max:
                        </Text>
                        <NumberInput
                          error={
                            minMaxError.find(e => e.index === index) &&
                            !minMaxError.find(e => e.index === index)?.min
                          }
                          value={chart.data[index].max}
                          allowUndefined
                          disabled={!!chart.max || !!chart.commonY}
                          onChange={val => {
                            const seriesOptions = chart.data[index]
                            if (
                              val &&
                              seriesOptions &&
                              seriesOptions.min &&
                              val < seriesOptions.min
                            ) {
                              setMinMaxError(prev => [
                                ...prev,
                                {
                                  index,
                                  message: 'Max value must exceed min value',
                                  min: false,
                                },
                              ])
                              return null
                            }
                            setMinMaxError(prev =>
                              prev.filter(e => e.index !== index),
                            )
                            return debouncedSetOptions(index, { max: val })
                          }}
                          placeholder="Auto"
                        />
                      </div>
                    </div>
                    {minMaxError.find(e => e.index === index) && (
                      <Text
                        variant="small"
                        bold
                        className="text-delete-primary"
                      >
                        {minMaxError.find(e => e.index === index)?.message}
                      </Text>
                    )}
                    {chart.data[index].type !== 'tag' && (
                      <Button
                        as="a"
                        href={`${rootLink}/models/${chart.data[index].id}`}
                        textVariant="description"
                        variant="secondary"
                        title="Go to model"
                      />
                    )}
                    <IfLabsAccess>
                      <div className="flex items-center justify-between gap-xs">
                        <Button
                          textVariant="description"
                          title="Tag Analysis"
                          variant="secondary"
                          as="a"
                          href={`${rootLink}/labs/tag_analysis?${queryString.stringify(
                            {
                              tag_id: chart.data[index].id,
                              from: new Date(timeRange.from).toISOString(),
                              to: new Date(timeRange.to).toISOString(),
                            },
                          )}`}
                          target="_blank"
                        />
                        <Button
                          textVariant="description"
                          title="Similarity Search"
                          variant="secondary"
                          as="a"
                          href={`${rootLink}/labs/similarity_search?${queryString.stringify(
                            {
                              tag_id: chart.data[index].id,
                              from: new Date(timeRange.from).toISOString(),
                              to: new Date(timeRange.to).toISOString(),
                            },
                          )}`}
                          target="_blank"
                        />
                      </div>
                    </IfLabsAccess>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}
    >
      {({ getTriggerProps, triggerRef: ref }) => (
        <div
          className="mx-xs flex w-[100px] cursor-pointer items-center gap-2xs leading-[0]"
          {...getTriggerProps({ ref })}
        >
          <Icon icon={light('gear')} />
          <Text variant="description" bold>
            Chart Settings
          </Text>
        </div>
      )}
    </MenuWrapper>
  )
}
