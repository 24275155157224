import { Routes, Route } from 'react-router-dom'
import { AccountPage } from './pages/account'
import { NotFoundPage } from 'pages/app'
import {
  OrgList,
  OrgsSitesMainPage,
  SiteInvitations,
  SiteList,
  SiteDetailsPage,
  SiteGateways,
  SiteUsers,
  DataSubscriptions,
  OpcUaConnectionPage,
  OpcUaDetailsTab,
  OpcUaConfigurationTab,
  GatewayDetailsPage,
  OpcConnections,
  OpcUaSubscriptionsTab,
  OpcUaSubscriptionPage,
} from './pages/orgs-sites'
import { OrgDetailsPage, OrgSites, OrgUsers } from './pages/orgs-sites/org'
import { OpcUaBrowse } from './pages/orgs-sites/site/opc-ua-connection/OpcUaBrowse'

export function SettingsRouter(): JSX.Element {
  return (
    <Routes>
      <Route index element={<AccountPage />} />
      <Route path="orgs" element={<OrgsSitesMainPage />}>
        <Route index element={<OrgList />} />
        <Route path="sites" element={<SiteList />} />
        <Route path="invitations" element={<SiteInvitations />} />
      </Route>
      <Route path="orgs/*">
        <Route path=":orgId" element={<OrgDetailsPage />}>
          <Route index element={<OrgUsers />} />
          <Route path="sites" element={<OrgSites />} />
        </Route>

        <Route
          path=":orgId/sites/:siteId/gateways/:gatewayId/opc-ua/:connectionId/browse"
          element={<OpcUaBrowse />}
        />
        <Route
          path=":orgId/sites/:siteId/gateways/:gatewayId/opc-ua/:connectionId?"
          element={<OpcUaConnectionPage />}
        >
          <Route index element={<OpcUaDetailsTab />} />
          <Route path="subscriptions" element={<OpcUaSubscriptionsTab />} />
          <Route path="configuration" element={<OpcUaConfigurationTab />} />
        </Route>
        <Route
          path=":orgId/sites/:siteId/gateways/:gatewayId/opc-ua/:connectionId/subscriptions/:subscriptionId"
          element={<OpcUaSubscriptionPage />}
        />
        <Route
          path=":orgId/sites/:siteId/gateways/:gatewayId"
          element={<GatewayDetailsPage />}
        >
          <Route index element={<OpcConnections />} />
          <Route path="certificates" element={<div>Certificates page</div>} />
        </Route>
        <Route path=":orgId/sites/:siteId" element={<SiteDetailsPage />}>
          <Route index element={<SiteUsers />} />
          <Route path="gateways" element={<SiteGateways />} />
          <Route path="data-subscriptions" element={<DataSubscriptions />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  )
}
