import React from 'react'
import { Button, Modal } from 'src/components/ui'

interface ConfirmationProps {
  onClickCross?: () => void
  onClickConfirm?: () => void
  onClickCancel?: () => void
  children?: React.ReactNode
}

export function ConfirmationModal({
  onClickCross = () => {},
  onClickConfirm,
  onClickCancel,
  children,
}: ConfirmationProps): JSX.Element {
  return (
    <Modal title="Confirmation" isOpen close={onClickCross}>
      <div className="mt-3 flex flex-col justify-center gap-4 px-4">
        <div>{children}</div>
        <div className="flex flex-row justify-center gap-2">
          <Button variant="primary" title="Confirm" onClick={onClickConfirm} />
          <Button variant="secondary" title="Cancel" onClick={onClickCancel} />
        </div>
      </div>
    </Modal>
  )
}
