import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useParams } from 'react-router-dom'
import { Text, SelectInput, Icon } from 'src/components/ui'
import { useOpcConnections } from '../../opc-ua-connection/opc-ua-connection.api'

type Props = {
  selectedConnection: string
  setSelectedConnection: (value: string) => void
  gatewayId: string
  handleCreateConnection: () => void
}

export function OpcConnectionSelector({
  selectedConnection,
  setSelectedConnection,
  gatewayId,
  handleCreateConnection,
}: Props): JSX.Element {
  const { siteId } = useParams()
  if (!siteId) throw new Error('siteId is required')
  const { data: opcConnections, isLoading } = useOpcConnections(
    siteId,
    gatewayId,
  )
  const connection = opcConnections?.find(
    opcConnection => opcConnection.id === selectedConnection,
  )
  const selectedOption = {
    label: connection?.name ?? '',
    value: selectedConnection,
  }
  const options =
    opcConnections?.map(opcConnection => ({
      label: opcConnection.name,
      value: opcConnection.id,
    })) ?? []
  return (
    <div className="flex flex-col gap-2xs">
      <Text variant="description" bold>
        Select OPC-UA Server Connection
      </Text>
      <SelectInput
        bold
        placeholder="Select OPC-UA Server Connection"
        textVariant="description"
        value={selectedOption}
        options={options}
        onChange={value => setSelectedConnection(value)}
        dropdownClassName="max-h-[200px] overflow-y-auto"
        isLoading={isLoading}
        dropdownFooter={
          <div
            className="flex items-center gap-xs p-xs hover:bg-grey-50"
            onClick={handleCreateConnection}
          >
            <Icon
              icon={regular('circle-plus')}
              className="text-green-primary"
            />
            <Text variant="description" bold>
              Create OPC-UA Connection
            </Text>
          </div>
        }
      />
    </div>
  )
}
