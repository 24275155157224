export type SeriesId =
  | {
      readonly id: string
      readonly type: 'tag' | 'prediction' | 'spikeScore' | 'shortScore'
    }
  | {
      readonly id: string
      readonly type: 'forecast'
      readonly offset: number
    }

export type SeriesType = SeriesId['type']

type DisplayOptions = {
  readonly min?: number
  readonly max?: number
  readonly colorIndex?: number
  readonly inactive?: boolean
  readonly disableRange?: boolean
  // only used for scatter charts
  readonly axis?: ChartAxis
}

export type SeriesOptions = SeriesId & DisplayOptions

export function seriesIdFromOptions(options: SeriesOptions): SeriesId {
  const { id, type } = options
  if (type === 'forecast') {
    return { id, type, offset: options.offset }
  }
  return { id, type }
}

export enum ChartType {
  TimeSeries = 'time-series',
  Scatter = 'scatter',
}

export function getChartTypeLabel(type: ChartType): string {
  switch (type) {
    case ChartType.TimeSeries:
      return 'Time Series'
    case ChartType.Scatter:
      return 'Scatter Plot'
    default: {
      const exhaustiveCheck: never = type
      return exhaustiveCheck
    }
  }
}

export enum ChartAxis {
  X = 'x',
  Y = 'y',
}

export interface ChartOptions {
  readonly id: number
  readonly data: readonly SeriesOptions[]
  readonly type: ChartType
  readonly commonY?: boolean
  readonly min?: number
  readonly max?: number
  // used for the color of a scatter chart
  readonly colorIndex?: number
}

export type ChartTableProps = {
  chart: ChartOptions
  addSeries: (data: SeriesOptions, axis?: ChartAxis) => void
  removeSeries: (id: SeriesId) => void
}

// for layout
type TablePropsLayout = ChartTableProps
export type TableComponentLayout = (props: TablePropsLayout) => JSX.Element

export const ChartSizes = {
  small: {
    size: 300,
    maxSize: 500,
  },
  medium: {
    size: 500,
    maxSize: 800,
  },
  large: {
    size: 800,
    maxSize: 1200,
  },
} as const

export type ChartSize = keyof typeof ChartSizes

export function isChartSize(s: any): s is ChartSize {
  return Object.keys(ChartSizes).includes(s)
}

export type ChartLayoutConfig = {
  selectedSize: ChartSize
  isFullWidth: boolean
}
