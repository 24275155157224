import { formatISO } from 'date-fns'
import { graphqlApi } from './graphQL'
import {
  GqlActivateModelInput,
  GqlAddStarInput,
  GqlAnomalyConditionInput,
  GqlArchiveModelInput,
  GqlDeleteModelInput,
  GqlFetchModelFragment,
  GqlModelBaseFragment,
  GqlModelIdStateFragment,
  GqlModelTypeFragment,
  GqlRemoveStarInput,
  GqlRunAnomalyDetectionInput,
  GqlStarrableStateFragment,
  GqlStartModelInput,
  GqlStopModelInput,
  GqlUpdateModelAnomalyConditionsFragment,
  GqlUpdateModelFilterFragment,
  GqlUpdateModelThresholdsFragment,
} from './graphqlTypes'

interface FactoryID {
  factory: string
}

export async function fetchModelTypes(): Promise<GqlModelTypeFragment[]> {
  const api = await graphqlApi()
  const data = await api.FetchModelTypes()
  return data.modelTypes
}

export async function fetchModels({
  factory,
}: FactoryID): Promise<GqlModelBaseFragment[]> {
  const api = await graphqlApi()
  const data = await api.FetchModels({
    factory,
  })
  if (data.factory?.models?.items) {
    // flatMap is used to remove nulls
    return data.factory.models.items.flatMap(f => (f ? [f] : [])).reverse()
  }
  // FIXME this is probably an error state
  return []
}

interface FetchModel {
  modelId: string
}

export async function fetchModel({
  modelId,
}: FetchModel): Promise<GqlFetchModelFragment | undefined | null> {
  const api = await graphqlApi()
  const data = await api.FetchModel({ modelId })
  switch (data.node?.__typename) {
    case 'AnomalyModel':
      return data.node
    case 'ForecastModel':
      return data.node
    default:
      return null
  }
}

export async function archiveModel(
  input: GqlArchiveModelInput,
): Promise<GqlModelIdStateFragment | undefined | null> {
  const api = await graphqlApi()
  const data = await api.ArchiveModel({ input })
  return data.archiveModel?.model
}

export async function deleteModel(
  input: GqlDeleteModelInput,
): Promise<GqlModelIdStateFragment | undefined | null> {
  const api = await graphqlApi()
  const data = await api.DeleteModel({ input })
  return data.deleteModel?.model
}

export async function addStar(
  input: GqlAddStarInput,
): Promise<GqlStarrableStateFragment | undefined | null> {
  const api = await graphqlApi()
  const data = await api.AddStar({ input })
  return data.addStar?.starrable
}

export async function removeStar(
  input: GqlRemoveStarInput,
): Promise<GqlStarrableStateFragment | undefined | null> {
  const api = await graphqlApi()
  const data = await api.RemoveStar({ input })
  return data.removeStar?.starrable
}

export async function activateModel(
  input: GqlStartModelInput,
): Promise<GqlModelIdStateFragment | undefined | null> {
  const api = await graphqlApi()
  const data = await api.StartModel({ input })
  return data.startModel?.model
}

export async function deactivateModel(
  input: GqlStopModelInput,
): Promise<GqlModelIdStateFragment | undefined | null> {
  const api = await graphqlApi()
  const data = await api.StopModel({ input })
  return data.stopModel?.model
}

export async function startModelTraining(
  input: GqlActivateModelInput,
): Promise<GqlModelIdStateFragment | undefined | null> {
  const api = await graphqlApi()
  const data = await api.ActivateModel({ input })
  return data.activateModel?.model
}

export async function runAnomalyDetection(
  input: GqlRunAnomalyDetectionInput,
): Promise<GqlModelIdStateFragment | undefined | null> {
  const api = await graphqlApi()
  const data = await api.RunAnomalyDetection({ input })
  return data.runAnomalyDetection?.model
}

export interface UpdateModel {
  id: string
  name?: string
  description?: string
  typeId?: string
  methodId?: string
  tagId?: string
  inputTagIds?: string[]
  trainingStart?: Date | number
  trainingEnd?: Date | number
  trainingPeriod?: string
}

export async function updateModel({
  id,
  name,
  description,
  typeId,
  methodId,
  tagId,
  inputTagIds,
  trainingStart,
  trainingEnd,
  trainingPeriod,
}: UpdateModel): Promise<GqlModelBaseFragment | undefined | null> {
  const api = await graphqlApi()
  const data = await api.UpdateModel({
    input: {
      id,
      name,
      description,
      typeId,
      methodId,
      tagId,
      inputTagIds,
      trainingStart: trainingStart ? formatISO(trainingStart) : undefined,
      trainingEnd: trainingEnd ? formatISO(trainingEnd) : undefined,
      trainingPeriod,
    },
  })
  return data.updateModel?.model
}

export interface InputTag {
  modelId: string
  tagId: string
}

export async function removeInputTag({
  modelId,
  tagId,
}: InputTag): Promise<GqlModelBaseFragment | undefined | null> {
  const api = await graphqlApi()
  const data = await api.RemoveInputTagsFromModel({
    input: {
      id: modelId,
      tagIds: [tagId],
    },
  })
  return data.removeInputTagsFromModel?.model
}

export async function addInputTag({
  modelId,
  tagId,
}: InputTag): Promise<GqlModelBaseFragment | undefined | null> {
  const api = await graphqlApi()
  const data = await api.AddInputTagsToModel({
    input: {
      id: modelId,
      tagIds: [tagId],
    },
  })
  return data.addInputTagsToModel?.model
}

export interface CreateModel {
  name: string
  description?: string
  typeId: string
  methodId: string
  tagId: string
  inputTagIds: string[]
  trainingStart?: Date | number
  trainingEnd?: Date | number
  trainingPeriod?: string
  factory: string
}

export async function createModel({
  name,
  description,
  typeId,
  methodId,
  tagId,
  inputTagIds,
  trainingStart,
  trainingEnd,
  trainingPeriod,
  factory,
}: CreateModel): Promise<GqlModelBaseFragment | undefined | null> {
  const api = await graphqlApi()
  const data = await api.CreateModel({
    input: {
      name,
      description,
      typeId,
      methodId,
      tagId,
      inputTagIds,
      trainingStart: trainingStart ? formatISO(trainingStart) : undefined,
      trainingEnd: trainingEnd ? formatISO(trainingEnd) : undefined,
      trainingPeriod,
      factoryId: factory,
    },
  })
  return data.createModel?.model
}

interface UpdateModelThresholds {
  id: string
  spikeAnomalyThreshold?: number
  shortAnomalyThreshold?: number
}

export async function updateModelThresholds({
  id,
  spikeAnomalyThreshold,
  shortAnomalyThreshold,
}: UpdateModelThresholds): Promise<
  GqlUpdateModelThresholdsFragment | undefined
> {
  const api = await graphqlApi()
  const data = await api.UpdateModelThresholds({
    input: {
      modelId: id,
      spikeAnomalyThreshold,
      shortAnomalyThreshold,
    },
  })
  if (data.updateModelThresholds?.model?.__typename === 'AnomalyModel') {
    return data.updateModelThresholds.model
  }
  return undefined
}

interface UpdateModelAnomalyConditions {
  id: string
  anomalyConditions: GqlAnomalyConditionInput[]
}

export async function updateModelAnomalyConditions({
  id,
  anomalyConditions,
}: UpdateModelAnomalyConditions): Promise<
  GqlUpdateModelAnomalyConditionsFragment | undefined
> {
  const api = await graphqlApi()
  const data = await api.UpdateModelAnomalyConditions({
    input: {
      modelId: id,
      conditions: anomalyConditions,
    },
  })
  if (data.updateModelAnomalyConditions?.model?.__typename === 'AnomalyModel') {
    return data.updateModelAnomalyConditions.model
  }
  return undefined
}

export interface UpdateModelFilter {
  id: string
  anomalyGenerationFilter?: string
}

export async function updateModelFilter({
  id,
  anomalyGenerationFilter,
}: UpdateModelFilter): Promise<GqlUpdateModelFilterFragment | undefined> {
  const api = await graphqlApi()
  const data = await api.UpdateModelFilter({
    input: {
      modelId: id,
      anomalyGenerationFilter,
    },
  })
  if (data.updateModelFilter?.model?.__typename === 'AnomalyModel') {
    return data.updateModelFilter.model
  }
  return undefined
}
