import { useParams } from 'react-router-dom'
import { useTitle } from 'src/utility'
import { Text, Spinner, Card } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { ErrorDisplay } from 'pages/app'
import { useAnomaly } from 'anomalies/api'
import { Comments, Details, Navigation, Overview } from './sections'

export function AnomalyPage(): JSX.Element {
  const { anomalyId } = useParams()
  if (!anomalyId) throw new Error('`anomalyId` route param missing')
  useTitle(`Anomaly ${anomalyId}`)
  const { id: factory } = useSite()

  const {
    data: anomaly,
    isError,
    isLoading,
    error,
  } = useAnomaly(factory, anomalyId)

  if (isError) {
    return <ErrorDisplay error={error} message="Anomaly not found" />
  }

  return (
    <div className="m-[0.5em] mt-0 grid grid-cols-[minmax(0,1fr)_26em] gap-[0.5em]">
      {isLoading ? (
        <>
          <Card>
            <Text variant="title" bold className="!text-2xl">
              Anomaly
            </Text>
            <Spinner />
          </Card>
          <Card />
        </>
      ) : (
        <>
          <Overview anomaly={anomaly} />
          <Navigation anomaly={anomaly} />
          <Comments anomaly={anomaly} />
          <Details anomaly={anomaly} />
        </>
      )}
    </div>
  )
}
