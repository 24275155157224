let api
let auth
let stack
let ws
let ldid

const prodHost = 'api.intelecy.io'
// const devHost = 'intelecyapi-westeurope-dev.azurewebsites.net'
const devHost = 'api-dev.intelecy.io'

const prod = {
  API_ROOT: `https://${prodHost}`,
  WS_ROOT: `wss://${prodHost}`,
  AUTH_CLIENT_ID: '4MWOv7DTbQFjJaOM0gDNCwJZO0lQ7dMT',
  LAUNCH_DARKLY_ID: '6284dbdbbed873156be6121e',
}

const dev = {
  API_ROOT: `https://${devHost}`,
  WS_ROOT: `wss://${devHost}`,
  AUTH_CLIENT_ID: 'HYmLbrvBPRus3mY7twD96K3EeGbbqI9F',
  LAUNCH_DARKLY_ID: '6284dbdbbed873156be6121d',
}

switch (window.location.hostname) {
  case 'intelecy.io':
  case 'www.intelecy.io':
  case 'intelecyfront.azurewebsites.net':
  case 'intelecyfront-westeurope.azurewebsites.net':
    api = prod.API_ROOT
    auth = prod.AUTH_CLIENT_ID
    stack = 'prod'
    ws = prod.WS_ROOT
    ldid = prod.LAUNCH_DARKLY_ID
    break

  case 'staging.intelecy.io':
  case 'intelecyfront-staging.azurewebsites.net':
  case 'intelecyfront-westeurope-staging.azurewebsites.net':
    api = prod.API_ROOT
    auth = prod.AUTH_CLIENT_ID
    stack = 'staging'
    ws = prod.WS_ROOT
    ldid = prod.LAUNCH_DARKLY_ID
    break

  case 'dev.intelecy.io':
    api = dev.API_ROOT
    auth = dev.AUTH_CLIENT_ID
    stack = 'dev'
    ws = dev.WS_ROOT
    ldid = dev.LAUNCH_DARKLY_ID
    break

  case 'localhost':
  default:
    // only used with `yarn run start-prod-api`
    if (window.location.port === '5173') {
      api = prod.API_ROOT
      auth = prod.AUTH_CLIENT_ID
      stack = 'prod'
      ws = prod.WS_ROOT
      ldid = prod.LAUNCH_DARKLY_ID
      break
    }

    api = dev.API_ROOT
    auth = dev.AUTH_CLIENT_ID
    stack = 'unknown'
    ws = dev.WS_ROOT
    ldid = dev.LAUNCH_DARKLY_ID
    break
}

export const API_ROOT = api
export const AUTH_CLIENT_ID = auth
export const STACK = stack
export const WS_ROOT = ws
export const LAUNCH_DARKLY_ID = ldid
