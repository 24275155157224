import { Button, Text, Alert } from 'src/components/ui'

export function DataExplorerLandingPage(): JSX.Element {
  return (
    <div className="mx-s mt-[48px] flex h-[calc(100%-68px)] w-[calc(100%-32px)] flex-col gap-4 rounded-2xs bg-white p-6 px-l">
      <Alert
        variant={'warning'}
        primaryMessage={'Data Explorer is not enabled for this site.'}
      />

      <div className="flex max-w-screen-lg gap-4">
        <div className="w-1/2 flex-row">
          <p className="font-rubik text-3xl leading-title">
            Intelecy Data Explorer
          </p>

          <p className="max-w-prose font-roboto text-xl">
            A powerful set of analytics that enable deep insight into both{' '}
            <strong>what</strong> is happening in your processes and{' '}
            <strong>why</strong> it is happening.
          </p>

          <p>
            <Text variant={'content'} bold={true}>
              Features:
            </Text>
            <Text variant={'content'}>
              <ul className={'space-y-1.5 pl-8 leading-normal'}>
                <li>
                  Segment your process data into batches, calculate yield, and
                  uncover previously unknown correlations
                </li>
                <li>
                  Easily extend, combine or manipulate raw data by applying
                  mathematical transforms
                </li>
                <li>
                  Quickly visualize relationships between any time-series data
                </li>
                <li>
                  Collect and persist related data, visualizations and
                  definitions into case-specific workspaces
                </li>
                <li>
                  Create reproducible, dynamic reports and share securely with
                  colleagues outside of Intelecy (click on images to the right
                  to see examples)
                </li>
              </ul>
            </Text>
          </p>

          <Text variant={'description'} className={'pb-4 italic'}>
            Data Explorer is a paid add-on to the base Intelecy subscription.
            Please contact your account manager for more information.
          </Text>

          <Button
            title={'Start a free trial or get a demo'}
            variant={'primary'}
            onClick={e => {
              window.location.href = 'mailto:support@intelecy.com'
              e.preventDefault()
            }}
          />
        </div>

        <div className="w-1/2 flex-row pt-4">
          <div className="pb-8">
            <a
              href={
                'https://dex.intelecy.io/shared/Juice-Pressure-Correlation-3GvjG9XKmgUZBu2aF.html'
              }
              target={'_blank'}
              rel={'noreferrer'}
            >
              <img
                className="w-full max-w-lg object-scale-down pl-4"
                src={'/assets/dex/screenshot-2.png'}
                alt={'Data Explorer screenshot #2'}
                title={'Click for fully interactive example analysis.'}
              />
            </a>
            <Text
              variant={'description'}
              className="grid w-full place-items-center"
            >
              Example: Comparing two pressure sensors, coloring by process step
              number
            </Text>
          </div>
          <div>
            <a
              href={
                'https://dex.intelecy.io/shared/Permeate-Pressure-Relations-5KntAWDJgDHu6sHSH.html'
              }
              target={'_blank'}
              rel={'noreferrer'}
            >
              <img
                className="w-full max-w-lg object-scale-down pl-4"
                src={'/assets/dex/screenshot-3.png'}
                alt={'Data Explorer screenshot #3'}
                title={'Click for fully interactive example analysis.'}
              />
            </a>
            <Text
              variant={'description'}
              className="grid w-full place-items-center"
            >
              Example: Visualizing a year of product batches with multiple
              attributes and dimensions
            </Text>
          </div>
        </div>
      </div>
    </div>
  )
}
