import { Text, TextInput, Option, RadioGroupInput } from 'src/components/ui'
import { OpcUaConnectionConfiguration } from '../../opc-ua-connection.types'
import { useEffect, useState } from 'react'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

type Props = {
  disabled?: boolean
  config: OpcUaConnectionConfiguration
  setConfig: (config: OpcUaConnectionConfiguration) => void
}

export function SecurityAuthenticationMethod({
  disabled,
  config,
  setConfig,
}: Props): JSX.Element {
  const [anonymousDisabled, setAnonymousDisabled] = useState(false)
  const [usernameDisabled, setUsernameDisabled] = useState(false)

  useEffect(() => {
    const { securityOptions, securityType, securityPolicy, securityAnonymous } =
      config
    const selectedPolicy = securityOptions?.securityTypes
      .find(type => type.value === securityType)
      ?.policies.find(policy => policy.value === securityPolicy)

    if (selectedPolicy) {
      const { anonymous, username } = selectedPolicy.authenticationMethods
      const updateAnonymous = !anonymous && securityAnonymous
      const updateUsername = !username && !securityAnonymous

      setAnonymousDisabled(!anonymous)
      setUsernameDisabled(!username)

      if (updateAnonymous || updateUsername) {
        setConfig({
          ...config,
          securityAnonymous: !config.securityAnonymous,
        })
      }
    }
  }, [config, setConfig])

  const options = [
    {
      label: 'Anonymous',
      value: 'anonymous',
      disabled: anonymousDisabled,
    },
    {
      label: 'User Credentials',
      value: 'user-credentials',
      disabled: usernameDisabled,
    },
  ]

  const handleChange = (option: Option): void => {
    if (option.value === 'anonymous') {
      setConfig({ ...config, securityAnonymous: true })
    } else {
      setConfig({ ...config, securityAnonymous: false })
    }
  }

  const selectedOption = config.securityAnonymous ? options[0] : options[1]
  return (
    <div className="flex flex-1 flex-col gap-s rounded-2xs border border-solid border-grey-100 p-s">
      <div>
        <Text variant="description">Select Authentication Method</Text>

        <Text bold className="inline">
          User Authentication
        </Text>
      </div>
      <RadioGroupInput
        selected={selectedOption}
        setSelected={handleChange}
        options={options}
        disabled={disabled}
      />
      <div>
        {!config.securityAnonymous && (
          <>
            <TextInput
              disabled={disabled}
              className="max-w-[250px]"
              label="Username"
              labelVariant="description"
              value={config.userName ?? ''}
              onChange={e => setConfig({ ...config, userName: e.target.value })}
              iconRight={light('circle-xmark')}
              onIconRightClick={() => setConfig({ ...config, userName: '' })}
            />
            <TextInput
              disabled={disabled}
              className="mt-s max-w-[250px]"
              label="Password"
              labelVariant="description"
              value={config.password ?? ''}
              onChange={e => setConfig({ ...config, password: e.target.value })}
              type="password"
              iconRight={light('circle-xmark')}
              onIconRightClick={() => setConfig({ ...config, password: '' })}
            />
          </>
        )}
      </div>
    </div>
  )
}
