import { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'
import { Button, Text } from 'src/components/ui'
import { useClickOutside } from 'src/utility'
import { useAuth } from 'src/contexts/auth'
import { useSite } from 'src/contexts/site'
import { useCharts } from 'src/contexts/charts'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export function ShareDropdown({ isOpen, setIsOpen }: Props): JSX.Element {
  const [isCopied, setIsCopied] = useState(false)
  const { id: factory } = useSite()
  const {
    viewer: { factories },
  } = useAuth()
  const { charts, generateChartUrl } = useCharts()

  const factoryName = factories.find(el => el.id === factory)?.name
  const dropdownRef = useRef<HTMLDivElement>(null)
  useClickOutside(dropdownRef, () => isOpen && setIsOpen(false))

  useEffect(() => {
    if (charts.length === 0 && isOpen) {
      setIsOpen(false)
    }
  }, [charts.length, isOpen, setIsOpen])

  const handleCopyLink = (): void => {
    const url = generateChartUrl()
    navigator.clipboard.writeText(url)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 1000)
  }
  return (
    <div
      ref={dropdownRef}
      className={classNames(
        'absolute right-0 top-[calc(100%+4px)] z-50 flex w-full min-w-[220px] flex-col rounded-2xs border border-solid border-grey-100 bg-white p-xs shadow-sm transition-all',
        isOpen
          ? 'opacity-100 scale-100'
          : 'opacity-0 scale-80 pointer-events-none',
      )}
    >
      <Button
        variant="primary"
        title={isCopied ? 'Link Copied' : 'Copy link'}
        onClick={handleCopyLink}
      />
      <Text variant="description" className="mt-xs">
        Anyone with the link, that has access to{' '}
        <span className="font-500">{factoryName ?? 'factory'}</span>, will be
        able to view.
      </Text>
    </div>
  )
}
