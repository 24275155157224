import { UseQueryResult, useQuery } from '@tanstack/react-query'
import { useSite } from 'src/contexts/site'
import * as api from 'src/services'

export function useAnomalyCountByState(): UseQueryResult<api.StateCount[]> {
  const { id } = useSite()
  const req = { id }
  return useQuery({
    queryKey: ['fetchAnomalyCountByState', req],
    queryFn: () => api.fetchAnomalyCountByState(req),
  })
}
