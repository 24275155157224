import { useQuery, UseQueryResult } from '@tanstack/react-query'
import { useSite } from 'src/contexts/site'
import * as api from 'src/services'

const LATEST_COMMENTS_KEY = 'fetchLatestAnomalyComments'

export function useLatestAnomalyComments(): UseQueryResult<
  api.GqlAnomalyCommentFragment[]
> {
  const { id: factory } = useSite()
  return useQuery({
    queryKey: [LATEST_COMMENTS_KEY, factory],
    queryFn: async () => api.fetchLatestAnomalyComments(factory),
  })
}
