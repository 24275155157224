import { Text, TextInput } from 'src/components/ui'
import { GqlGatewayFragment } from 'src/services'

type Props = {
  disabled?: boolean
  selectedGateway: GqlGatewayFragment
  serverEndpoint: string
  connectionName: string
  handleEndpointChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  handleNameChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export function ConnectionDetails({
  disabled,
  selectedGateway,
  serverEndpoint,
  connectionName,
  handleEndpointChange,
  handleNameChange,
}: Props): JSX.Element {
  return (
    <div className="flex flex-1 flex-col gap-m rounded-2xs border border-solid border-grey-100 p-s">
      <div>
        <Text variant="description">Gateway ID</Text>
        <div>
          <Text className="inline" bold>
            {selectedGateway.hostname}
          </Text>
          <Text variant="code" className="ml-2xs inline">
            ({selectedGateway.id})
          </Text>
        </div>
      </div>
      <div className="flex gap-xl">
        <div>
          <Text variant="description">Hostname</Text>
          <Text bold>{selectedGateway?.hostname}</Text>
        </div>
        <div>
          <Text variant="description">Gateway Version</Text>
          <Text bold>{selectedGateway?.version}</Text>
        </div>
      </div>
      <div>
        <Text variant="description">OPC-UA Connection Configuration</Text>
        <Text bold>Specify a hostname and port for the connection</Text>
      </div>
      <TextInput
        disabled={disabled}
        className="max-w-[250px]"
        label="OPC-UA Server Endpoint"
        labelVariant="description"
        placeholder="OPC-UA Server Endpoint"
        summaryText="Specify the OPC-UA Server Endpoint. View examples"
        value={serverEndpoint}
        onChange={handleEndpointChange}
      />
      <TextInput
        disabled={disabled}
        className="max-w-[250px]"
        label="Connection Name"
        labelVariant="description"
        placeholder="Connection Name"
        summaryText="Give a unique name to your connection"
        value={connectionName}
        onChange={handleNameChange}
      />
    </div>
  )
}
