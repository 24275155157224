import { OnlyModelProps, SiteRole, TimeRangeOrPeriod } from 'src/types'
import { useSite } from 'src/contexts/site'
import { useModelTypes } from 'models/api'
import { NEW } from 'models/model/model.utils'
import { SelectInput, Text, TimePickerInput } from 'src/components/ui'
import { prettyDateRange } from 'src/utility/time'
import classNames from 'classnames'
import { PropertyContainer } from './PropertyContainer'
import { isModelEditing, modelValues } from '../modelOverview.state'
import { batch } from '@preact/signals-react'

const modelSubType = (name: string): string =>
  name?.replace(/Forecast (\d)H/g, (_, n) =>
    n === '1' ? '1 hour' : `${n} hours`,
  )

function ModelTypeInput(): JSX.Element {
  const typeId = modelValues.typeId.value
  const methodId = modelValues.methodId.value
  const modelTypesQuery = useModelTypes()
  const modelTypes = modelTypesQuery.data ?? []
  const methods = modelTypes.find(({ id }) => id === typeId)?.methods

  return (
    <>
      <div className="flex items-center gap-2xs">
        <Text bold>Type</Text>
        <div className="w-[170px]">
          <SelectInput
            value={
              typeId
                ? {
                    label:
                      modelTypes.find(({ id }) => id === typeId)?.name || '',
                    value: typeId,
                  }
                : undefined
            }
            options={modelTypes.map(({ id, name }) => ({
              label: name,
              value: id,
            }))}
            onChange={value => {
              if (value !== typeId) {
                const type = modelTypes.find(({ id }) => id === value)
                const methodId =
                  type?.methods.find(({ name }) =>
                    type.name === 'Forecast'
                      ? name === 'Forecast 1H'
                      : name === 'Nowcast',
                  )?.id ?? 'unknown'
                batch(() => {
                  modelValues.typeId.value = value
                  modelValues.methodId.value = methodId
                })
              }
            }}
          />
        </div>
      </div>
      <div className="flex items-center gap-2xs">
        <Text bold>Subtype</Text>:
        <div className="w-[100px]">
          <SelectInput
            options={
              methods?.map(({ id, name }) => ({
                label: modelSubType(name),
                value: id,
              })) ?? []
            }
            value={
              typeId
                ? {
                    label: modelSubType(
                      methods?.find(({ id }) => id === methodId)?.name ??
                        'unknown',
                    ),
                    value: methodId,
                  }
                : undefined
            }
            onChange={value => (modelValues.methodId.value = value)}
          />
        </div>
      </div>
    </>
  )
}

export function ModelTypeContainer({ model }: OnlyModelProps): JSX.Element {
  const { viewerRole: role } = useSite()

  const fromDate = model.activeTrainedModel?.dataStart || model.trainingStart
  const toDate = model.activeTrainedModel?.dataEnd || model.trainingEnd

  return (
    <div
      className={classNames(
        'flex whitespace-nowrap',
        isModelEditing.value
          ? 'flex-col gap-s'
          : 'flex-col gap-s small:gap-l small:flex-row small:items-center',
      )}
    >
      {isModelEditing.value &&
      model.state === NEW &&
      role !== SiteRole.READER ? (
        <>
          <div className="flex flex-col gap-s small:flex-row small:items-center">
            <ModelTypeInput />
          </div>
          <div className="flex items-center gap-2xs whitespace-nowrap">
            <Text bold>Training Period</Text>
            <TimePickerInput
              value={modelValues.trainingPeriod.value as TimeRangeOrPeriod}
              isLive={false}
              onChange={period => (modelValues.trainingPeriod.value = period)}
              maxDate={new Date()}
              position="left"
              showTimeOption={false}
            />
          </div>
        </>
      ) : (
        <>
          <PropertyContainer modelType={model.type.name}>
            <Text bold>Type</Text>
            <Text>
              {model.type.name}, {modelSubType(model.method.name)}
            </Text>
          </PropertyContainer>

          <PropertyContainer modelType={model.type.name}>
            <Text bold>Training Period</Text>
            <Text>
              {prettyDateRange({
                fromDate,
                toDate,
                timeInterval:
                  fromDate && toDate ? 'range' : model.trainingPeriod || 'P1Y',
              })}
            </Text>
          </PropertyContainer>
        </>
      )}
    </div>
  )
}
