import { DynamicTable, TableConfig, Text, Spinner } from 'src/components/ui'
import { hasRunInitialInference } from 'models/model/model.utils'
import { ChartAxis, SeriesType, ChartTableProps } from 'src/types/chartTypes'
import { useAnomalyModels } from 'models/api'
import { getAnomaliesConfig } from './table-configs'

const anomalySeriesTypes = new Set<SeriesType>([
  'prediction',
  'shortScore',
  'spikeScore',
])

export function AnomalyModelsTable(props: ChartTableProps): JSX.Element {
  const { data: anomalyModels = [], isLoading } = useAnomalyModels()

  const models = anomalyModels
    .filter(({ state }) => hasRunInitialInference(state))
    .flatMap(model => [
      {
        ...model,
        type: 'prediction',
        label: 'Prediction',
      },
      {
        ...model,
        type: 'spikeScore',
        label: 'Spike anomaly score',
      },
      {
        ...model,
        type: 'shortScore',
        label: 'Short anomaly score',
      },
    ])
    .map(model => {
      return {
        ...model,
        id: `${model.id}-${model.type}`,
        modelId: model.id,
        tag: {
          ...model.tag,
          displayTagName: model.tag.displayName
            ? model.tag.displayName
            : model.tag.tagName || '',
        },
      }
    })

  const getConfig = (): [
    TableConfig,
    string[],
    { id: string; axis: ChartAxis }[],
  ] => {
    const { chart, addSeries, removeSeries } = props

    const selected = chart.data.flatMap(({ id, type }) =>
      anomalySeriesTypes.has(type) ? [`${id}-${type}`] : [],
    )

    const selectedScatter = chart.data.flatMap(({ id, type, axis }) =>
      (anomalySeriesTypes.has(type) && axis === ChartAxis.X) ||
      axis === ChartAxis.Y
        ? [{ id: `${id}-${type}`, axis }]
        : [],
    )

    return [
      getAnomaliesConfig({
        addSeries,
        removeSeries,
        chartType: chart.type,
      }),
      selected,
      selectedScatter,
    ]
  }

  const [config, selected, selectedScatter] = getConfig()

  return (
    <div className="h-full">
      <Text variant="title" bold>
        Select anomaly models to view
      </Text>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="h-[calc(100%-24px)]">
          <DynamicTable
            id="TrendAnomalyModels"
            maxHeightFull
            data={models}
            config={config}
            selectedRows={selectedScatter.length ? undefined : selected}
            selectedScatter={selectedScatter}
            fuzzySearch
            fuzzySearchField={['name', 'tag.displayTagName', 'label']}
            shouldAutofocusSearch
          />
        </div>
      )}
    </div>
  )
}
