import { light, regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Icon, Text } from 'src/components/ui'
import { useClickOutside } from 'src/utility'
import { useOrganization, useOrganizations } from 'orgs-sites/org/api'
import { useMySites } from 'orgs-sites/site/api'
import { zIndex } from 'src/utility/constants/StyleConstants'
import { FactoryDropdown } from './FactoryDropdown'

interface OrganizationDropdownProps {
  orgId: string
  siteId: string | undefined
}

export function OrganizationDropdown({
  orgId,
  siteId,
}: OrganizationDropdownProps): JSX.Element {
  const navigate = useNavigate()
  const [isMenuOpened, setIsMenuOpened] = useState(false)
  // these fetch the org list and sites list from the me query
  const orgsQuery = useOrganizations()
  const sitesQuery = useMySites()
  // this fetches from the customer
  const orgQuery = useOrganization(orgId)
  const menuRef = useRef<HTMLDivElement>(null)

  const closeDropdown = (): void => {
    setIsMenuOpened(false)
  }

  useClickOutside(menuRef, closeDropdown)

  useEffect(() => {
    return () => {
      setIsMenuOpened(false)
    }
  }, [])

  const handleChildClick = (event: any): void => {
    event.stopPropagation()
  }

  if (orgsQuery.isSuccess && orgQuery.isSuccess && sitesQuery.isSuccess) {
    const userOrganization = orgsQuery.data.find(org => org.id === orgId)
    // FIXME we should probaby do something else when the org is not found
    const orgName = orgQuery.data?.name ?? 'Unknown'

    const siteName = (siteId: string): string => {
      return sitesQuery.data.filter(site => site.id === siteId)[0]?.name || ''
    }

    return (
      <>
        {/* The back button */}
        <div
          onClick={() => {
            setIsMenuOpened(false)
            navigate(
              siteId
                ? userOrganization
                  ? `/settings/orgs/${orgId}`
                  : '/settings/orgs/sites'
                : '/settings/orgs',
            )
          }}
        >
          <Icon
            icon={regular('circle-chevron-left')}
            size="big"
            className="mr-xs cursor-pointer"
          />
        </div>
        {/* The menu */}
        <div
          ref={menuRef}
          onClick={() => setIsMenuOpened(!isMenuOpened)}
          className="relative flex cursor-pointer items-center gap-xs"
        >
          {siteId ? (
            <Text variant="title" bold>
              <span className="mb-2xs text-content">{`${orgName} | `}</span>
              {siteName(siteId)}
            </Text>
          ) : (
            <Text variant="title" bold className="my-xs">
              {orgName}
            </Text>
          )}
          <Icon
            icon={light('angle-down')}
            className={`${
              isMenuOpened ? 'rotate-180' : 'rotate-0'
            } transition-transform duration-200 `}
          />
          <div
            className={`${
              isMenuOpened ? 'block' : 'hidden'
            } absolute left-0 top-[calc(100%+10px)] max-h-[300px] min-w-[160px] overflow-y-auto rounded-2xs bg-white pt-xs shadow-lg`}
            style={{ zIndex: zIndex.header }}
            onClick={handleChildClick}
          >
            <Text variant="small" className="px-xs">
              {siteId ? 'Select site' : 'Select organization'}
            </Text>
            {orgsQuery.data.map((org, index) => {
              // FIXME
              // FactoryMenuDropdown calls `useOrganizationSites` for every org
              if (siteId) {
                return (
                  <FactoryDropdown
                    key={org.id}
                    organization={org}
                    closeDropdown={() => setIsMenuOpened(false)}
                  />
                )
              }
              return (
                <div
                  key={org.id}
                  onClick={() => {
                    setIsMenuOpened(false)
                    navigate(`/settings/orgs/${org.id}`)
                  }}
                  className="cursor-pointer p-xs hover:bg-grey-background"
                  style={{
                    borderBottom:
                      index !== orgsQuery.data.length - 1
                        ? '0.5px solid #d9d9d9'
                        : 'none',
                  }}
                >
                  <Text variant="content" bold>
                    {org.name}
                  </Text>
                </div>
              )
            })}
          </div>
        </div>
      </>
    )
  }

  // Loading or error
  // FIXME we should probably display something more useful
  return <></>
}
