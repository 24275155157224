import { parseISO } from 'date-fns'
import { TimeSeriesData } from 'src/types'

export interface TimeSeriesInput {
  timestamp: string
  value: number
  minValue?: number | null
  maxValue?: number | null
}

export const processTimeSeries = (
  data: TimeSeriesInput[],
): TimeSeriesData[] => {
  return data.reduce((acc: TimeSeriesData[], p) => {
    const ts = parseISO(p.timestamp).valueOf()
    // skip duplicate time stamps
    if (acc.length === 0 || acc[acc.length - 1][0] !== ts) {
      acc.push([ts, p.value, p.minValue ?? undefined, p.maxValue ?? undefined])
    }
    return acc
  }, [])
}
