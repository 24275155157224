import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useEffect, useRef, useState } from 'react'
import { useClickOutside } from 'src/utility'
import { OrganizationUser, isOrgAdmin, OrganizationRole } from 'src/types'
import { Icon, Text } from 'src/components/ui'

export function OrganizationRoleSelector({
  role,
  userId,
  handleSaveRole,
  currentUser,
}: {
  role: OrganizationRole
  userId: string
  handleSaveRole: (role: OrganizationRole, userId: string) => Promise<void>
  currentUser?: OrganizationUser
}): JSX.Element {
  const [isEditing, setIsEditing] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [dropdownWidth, setDropdownWidth] = useState(0)
  const [selectedRole, setSelectedRole] = useState(role)
  const menuRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const dw = document.getElementById('dropdownWrapper')
    setDropdownWidth(dw?.clientWidth || 0)
  }, [dropdownOpen])

  useClickOutside(menuRef, () => {
    if (!dropdownOpen) {
      setSelectedRole(role)
      setIsEditing(false)
    } else {
      setDropdownOpen(false)
    }
  })

  const submit = async (): Promise<void> => {
    await handleSaveRole(selectedRole, userId)
    setIsEditing(false)
  }

  return (
    <span className="relative flex h-l w-full items-center gap-xs">
      {isEditing ? (
        <div className="flex items-center gap-xs" ref={menuRef}>
          <span
            onClick={() => isEditing && setDropdownOpen(!dropdownOpen)}
            className="relative min-w-[150px] px-2xs"
            id="dropdownWrapper"
            style={{ borderBottom: '1px solid black' }}
          >
            <Text variant="content" bold>
              {selectedRole}
            </Text>
            <div>
              <Icon
                icon={regular('angle-down')}
                className={`absolute right-0 top-1/2 -translate-y-1/2 transition duration-200 ${
                  dropdownOpen ? 'rotate-180' : 'rotate-0'
                }`}
              />
            </div>
          </span>
          <div onClick={submit}>
            <Icon
              icon={regular('floppy-disk')}
              size="big"
              className="cursor-pointer text-green-primary"
            />
          </div>
          {dropdownOpen && (
            <div
              className="absolute left-0 top-[calc(100%)] z-20 rounded-2xs border border-solid border-grey-disabled bg-white shadow-md"
              style={{ width: dropdownWidth }}
            >
              {Object.entries(OrganizationRole).map(([key, value]) => (
                <div
                  key={key}
                  onClick={() => {
                    setSelectedRole(value)
                    setDropdownOpen(false)
                  }}
                >
                  <Text
                    className="p-xs hover:bg-grey-background"
                    variant="content"
                  >
                    {value}
                  </Text>
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div ref={menuRef} className="flex items-center gap-xs">
          <Text variant="content" bold={isOrgAdmin(role)}>
            {selectedRole}
          </Text>
          {isOrgAdmin(currentUser?.role) && (
            <div
              className="flex items-center"
              onClick={() => setIsEditing(true)}
            >
              <Icon
                icon={regular('pen-circle')}
                size="big"
                className="cursor-pointer text-blue-edit"
              />
            </div>
          )}
        </div>
      )}
    </span>
  )
}
