import { useNavigate, useParams } from 'react-router-dom'
import toast from 'react-hot-toast'
import { Button, DynamicTable, Spinner } from 'src/components/ui'
import { useOpcConnections } from 'opc-ua/opc-ua-connection.api'
// TODO: This will get moved
import { getOpcConnectionsTableConfig } from 'src/components/ui/tables/DynamicTable'

export function OpcConnections(): JSX.Element {
  const { orgId, siteId, gatewayId } = useParams()
  if (!siteId || !gatewayId || !orgId)
    throw new Error('Missing siteId or gatewayId or orgId')

  const navigate = useNavigate()
  const { data, isLoading, isError } = useOpcConnections(siteId, gatewayId)

  const handleRowClick = (row: any): void => {
    navigate(
      `/settings/orgs/${orgId}/sites/${siteId}/gateways/${gatewayId}/opc-ua/${row.id}`,
    )
  }

  const handleCreateOpcConnection = (): void => {
    navigate(
      `/settings/orgs/${orgId}/sites/${siteId}/gateways/${gatewayId}/opc-ua/configuration`,
    )
  }

  if (isError) {
    toast.error('Unable to retrieve OPC-UA connections')
    navigate(`/settings/orgs/${orgId}/sites/${siteId}/gateways`)
    return <></>
  }

  return (
    <div className="flex flex-1 flex-col gap-s">
      <div className="flex items-center justify-between">
        <Button
          variant="primary"
          title="Create OPC-UA Connection"
          onClick={handleCreateOpcConnection}
        />
      </div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="h-full min-h-[400px]">
          <DynamicTable
            data={data}
            config={getOpcConnectionsTableConfig()}
            id="gatewayOpc"
            actions={{
              selectRow: handleRowClick,
            }}
          />
        </div>
      )}
    </div>
  )
}
