import { Formik, Form, FormikErrors } from 'formik'
import { Asset } from 'src/types'
import { Button, Text, TextInput } from 'src/components/ui'
import { Modal } from 'src/components/ui'

interface EditAssetNameModalProps {
  onClickCross: () => void
  onClickConfirm: (name: string) => void
  onClickCancel: () => void
  asset: Asset
  assets: Asset[]
}

interface FormValues {
  assetName: string
}

export function EditAssetNameModal({
  onClickCross,
  onClickConfirm,
  onClickCancel,
  asset,
  assets,
}: EditAssetNameModalProps): JSX.Element {
  return (
    <Modal title="Edit alias" isOpen close={onClickCross}>
      <div className="mt-3 flex flex-col justify-center gap-4 px-4">
        <div className="flex flex-col">
          <Text bold>Asset name:</Text> {asset.name}
        </div>
        <Formik
          initialValues={{
            assetName: asset
              ? asset.alias || asset.name
              : 'Could not find name',
          }}
          validateOnMount={true}
          validate={values => {
            const errors: FormikErrors<FormValues> = {}

            const lc = values.assetName.toLowerCase()
            const nameExists = assets.some(
              a =>
                a.name.toLowerCase() === lc ||
                (a.alias && a.alias.toLowerCase() === lc),
            )

            if (nameExists && values.assetName !== '') {
              errors.assetName =
                'Name or alias is already in use by another asset'
            }
            if (!values.assetName) {
              errors.assetName = 'Required Field'
            }
            return errors
          }}
          onSubmit={values => onClickConfirm(values.assetName)}
        >
          {({
            isValid,
            values,
            handleSubmit,
            handleChange,
            errors,
            submitForm,
          }) => (
            <Form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-2xs">
                <TextInput
                  label="Alias:"
                  name="assetName"
                  value={values.assetName}
                  onChange={handleChange}
                  isError={!!errors.assetName}
                />
                {errors.assetName && (
                  <Text variant="small" bold className="text-delete-primary">
                    {errors.assetName}
                  </Text>
                )}
              </div>
              <div className="mt-4 flex flex-row justify-center gap-2">
                <Button
                  variant="primary"
                  title="Confirm"
                  disabled={!isValid}
                  onClick={submitForm}
                />
                <Button
                  variant="secondary"
                  title="Cancel"
                  onClick={onClickCancel}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </Modal>
  )
}
