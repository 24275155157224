import { createContext } from 'react'
import { ChartLayoutConfig, ChartOptions } from 'src/types/chartTypes'

export interface ChartsContextType {
  chartConfig: ChartLayoutConfig
  charts: ChartOptions[]
  isViewChanged: boolean
  addChart: (chart: ChartOptions) => void
  removeChart: (id: number) => void
  setChart: (id: number, chart: ChartOptions) => void
  setChartOrder: (newOrder: number[]) => void
  setChartConfig: (newConfig: ChartLayoutConfig) => void
  generateChartUrl: () => string
  resetViewChanges: () => void
  loadClearView: () => void
}

export const ChartsContext = createContext<ChartsContextType | undefined>(
  undefined,
)
