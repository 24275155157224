import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { useOrganization } from 'orgs-sites/org/api'
import { Icon, Text, RoutingTabs } from 'src/components/ui'
import { useParams, Outlet } from 'react-router-dom'

export function OrgDetailsPage(): JSX.Element {
  const { orgId } = useParams()
  if (!orgId) throw new Error('`orgId` route param missing')
  const { data: org, isLoading } = useOrganization(orgId)

  const tabs = [
    {
      title: 'Organization Users',
      path: ``,
    },
    {
      title: 'Sites',
      path: `sites`,
    },
  ]

  return (
    <div className="m-s mt-0 flex h-[calc(100%-1em)] flex-col rounded-2xs bg-white p-m">
      {!isLoading && (
        <div className="mb-l flex w-full items-start justify-start gap-l">
          <Icon
            icon={light('house-building')}
            className="!h-[30px] !w-[36px] text-grey-disabled"
          />
          <div>
            <Text variant="description" className="mb-2xs">
              {org?.isHome ? 'Your home organization' : 'Organization'}
            </Text>
            <Text variant="content" bold className="mb-xs">
              {org?.name}
            </Text>
            <Text variant="small" className="mb-xs mt-s">
              Users in organization
            </Text>
            <Text variant="description" bold>
              {org?.userCount || 0}
            </Text>
          </div>
          <div>
            <Text variant="description" className="mb-2xs">
              Your role
            </Text>
            <Text variant="content" bold className="mb-xs">
              {org?.viewerRole}
            </Text>
            <Text variant="small" className="mb-xs mt-s">
              Sites in organization
            </Text>
            <Text variant="description" bold>
              {org?.numOfSites || 'No sites'}
            </Text>
          </div>
        </div>
      )}
      <RoutingTabs tabs={tabs} />
      <Outlet />
    </div>
  )
}
