import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import { humanDate, humanTime } from 'src/utility/time'
import { Icon, Text, Tooltip, TableConfig } from 'src/components/ui'
import { Link } from 'react-router-dom'

type Props = {
  actions: {
    edit: (id: string) => void
    delete: (id: string) => void
  }
  userId: string
  baseLink: string
}

export const getTrendViewsTableConfig = ({
  actions,
  userId,
  baseLink,
}: Props): TableConfig => {
  return {
    allowPaging: true,
    pageSize: 10,
    pageSizes: [10, 25, 50, 100, 200],
    allowSorting: true,
    columns: [
      {
        field: 'name',
        headerName: 'View Name',
        flex: 200,
        cellRenderer: ({ data: { id, name } }: any) => (
          <Link
            to={`${baseLink}/${id}`}
            className="flex size-full flex-1 items-center no-underline"
          >
            <Text bold className="inline text-green-primary">
              {name}
            </Text>
          </Link>
        ),
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        flex: 200,
        cellRenderer: ({ data: { createdAt } }: any) => (
          <Text>
            <span className="font-500">{humanDate(createdAt)} |</span>{' '}
            {humanTime(createdAt)}
          </Text>
        ),
      },
      {
        field: 'discoverable',
        headerName: 'Discoverable',
        flex: 200,
        cellRenderer: ({ data: { shared } }: any) => (
          <Text>{shared ? 'Yes' : 'No'}</Text>
        ),
      },
      {
        field: 'user.name',
        headerName: 'Owner',
        flex: 200,
        minWidth: 100,
        cellRenderer: ({
          data: {
            user: { name },
          },
        }: any) => <Text>{name}</Text>,
      },
      {
        field: 'updatedAt',
        headerName: 'Last Save',
        flex: 200,
        cellRenderer: ({ data: { updatedAt } }: any) => (
          <Text>
            <span className="font-500">{humanDate(updatedAt)} |</span>{' '}
            {humanTime(updatedAt)}
          </Text>
        ),
      },
      {
        field: 'customIcon',
        colId: 'actions',
        headerName: 'Actions',
        width: 150,
        cellRenderer: ({ data: { id, user } }: any) => {
          return (
            <div className="flex items-center gap-s">
              {userId === user.id && (
                <>
                  <Tooltip
                    direction="bottom"
                    render={() => <Text variant="description">Edit</Text>}
                  >
                    <Icon
                      icon={light('pen-circle')}
                      className="text-blue-edit"
                      size="big"
                      onClick={() => actions.edit(id)}
                    />
                  </Tooltip>
                  <Tooltip
                    direction="bottom"
                    render={() => <Text variant="description">Delete</Text>}
                  >
                    <Icon
                      icon={light('circle-trash')}
                      className="text-delete-primary"
                      size="big"
                      onClick={() => actions.delete(id)}
                    />
                  </Tooltip>{' '}
                </>
              )}
            </div>
          )
        },
      },
    ],
  }
}
