import {
  NOT_RUNNING,
  RUNNING,
  label,
  STREAMING,
} from 'models/model/model.utils'
import { DynamicTable, TableConfig, Text, Spinner } from 'src/components/ui'
import { ChartAxis, ChartTableProps } from 'src/types/chartTypes'
import { useForecastModels } from 'models/api'
import { getForecastConfig } from './table-configs'

export function ForecastsTable(props: ChartTableProps): JSX.Element {
  const { data: forecastModels = [], isLoading } = useForecastModels()

  const models = forecastModels
    .filter(({ state }) => [RUNNING, NOT_RUNNING, STREAMING].includes(state))
    .map(item => {
      return {
        ...item,
        tag: {
          ...item.tag,
          displayTagName: item.tag.displayName
            ? item.tag.displayName
            : item.tag.tagName || '',
        },
        label: label(item.state),
      }
    })

  const getConfig = (): [
    TableConfig,
    string[],
    { id: string; axis: ChartAxis }[],
  ] => {
    const { chart, addSeries, removeSeries } = props

    const selected = chart.data.flatMap(({ id, type }) =>
      type === 'forecast' ? [id] : [],
    )

    const selectedScatter = chart.data.flatMap(({ id, axis }) =>
      axis === ChartAxis.X || axis === ChartAxis.Y ? [{ id, axis }] : [],
    )
    return [
      getForecastConfig({
        addSeries,
        removeSeries,
        chartType: chart.type,
      }),
      selected,
      selectedScatter,
    ]
  }

  const [config, selected, selectedScatter] = getConfig()

  return (
    <div className="h-full">
      <Text variant="title" bold>
        Select forecasts to view
      </Text>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="h-[calc(100%-24px)]">
          <DynamicTable
            id="TrendForecasts"
            data={models}
            config={config}
            selectedRows={selectedScatter.length ? undefined : selected}
            selectedScatter={selectedScatter}
            maxHeightFull
            fuzzySearch
            fuzzySearchField={[
              'name',
              'tag.displayTagName',
              'method.name',
              'state',
            ]}
            shouldAutofocusSearch
          />
        </div>
      )}
    </div>
  )
}
