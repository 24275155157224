import type { Config } from 'tailwindcss'

export const theme = {
  screens: {
    small: '600px',
    medium: '1024px',
    large: '1280px',
  },
  colors: {
    'green-primary': '#00DC00',
    'green-secondary': '#CCF8CC',
    'green-tertiary': '#C4ECDB',
    'green-hover': '#1C961C',
    'green-active': '#106D10',
    'green-20': '#E5FBE5',
    'black-primary': '#000000',
    'black-secondary': '#333333',
    'grey-text': '#ABABAB',
    'grey-disabled': '#D9D9D9',
    'grey-100': '#D9D9D9',
    'grey-50': '#ECECEC',
    'grey-disabled-v2': '#D9D9D980',
    'grey-background': '#F5F5F5',
    'grey-background-v2': '#E5E5E5',
    white: '#FFFFFF',
    'delete-primary': '#F02020',
    'delete-secondary': '#F9BFBF',
    'delete-hover': '#C11515',
    'delete-50': '#E89D9D',
    'delete-20': '#FCD2D2',
    'yellow-primary': '#FFB800',
    'blue-edit': '#387DA6',
    'graph-blue': '#2F1DF5',
    'graph-red': '#821C2F',
    'graph-purple': '#8B2BF6',
    'graph-orange': '#ED703F',
    'graph-pink': '#EB3FF7',
    'graph-yellow': '#CBAC5D',
    'yellow-fav': '#FFB800',
    'off-green': '#CCF8CC',
    'off-blue': '#D7E5ED',
    'off-yellow': '#FFF1CC',
    'theme-primary': '#3366cc',
    'theme-danger': '#E85746',
    'brand-yellow': '#FFA500',
    'alert-success': {
      bg: 'rgba(204, 248, 204, 1)',
      DEFAULT: 'rgba(0, 220, 0, 1)',
    },
    'alert-info': {
      bg: 'rgba(215, 229, 237, 1)',
      DEFAULT: 'rgba(56, 125, 166, 1)',
    },
    'alert-warning': {
      bg: 'rgba(255, 241, 204, 1)',
      DEFAULT: 'rgba(255, 184, 0, 1)',
    },
    'alert-error': {
      bg: 'rgba(252, 210, 210, 1)',
      DEFAULT: 'rgba(240, 32, 32, 1)',
    },
    primary: '#3366cc',
    filter: 'rgba(0, 0, 0, 0.2)',
    prediction: '#F3C61D',
    danger: '#E85746',
    // THEME COLORS
    'brand-primary-lightest': 'var(--brand-primary-lightest)',
    'brand-primary-light': 'var(--brand-primary-light)',
    'border-selected': 'var(--border-selected)',
    'text-interactive': 'var(--text-interactive)',
  },
  fontSize: {
    title: '18px',
    content: '14px',
    description: '12px',
    small: '10px',
    code: '14px',
    medium: '16px',
    huge: '40px',
  },
  fontFamily: {
    rubik: ['Rubik', 'sans-serif'],
    roboto: ['Roboto', 'sans-serif'],
    'roboto-mono': ['Roboto Mono', 'monospace'],
  },
  lineHeight: {
    title: '24px',
    content: '18px',
    description: '16px',
    small: '12px',
    code: '20px',
    1.5: '1.5',
  },
  fontWeight: {
    700: '700',
    500: '500',
    400: '400',
    300: '300',
  },
  spacing: {
    '3xs': '2px',
    '2xs': '4px',
    xs: '8px',
    s: '16px',
    m: '24px',
    l: '32px',
    xl: '48px',
    '2xl': '56px',
    '3xl': '64px',
    'icon-small': '14px',
    'icon-big': '18px',
  },
  borderRadius: {
    '2xs': '4px',
    xs: '8px',
    s: '16px',
    m: '24px',
  },
  boxShadow: {
    button: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
    input: '0px 2px 4px rgba(0, 0, 0, 0.25)',
    'user-menu':
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);',
    'threshold-slider-thumb': '0px 1px 5px rgba(0, 0, 0, 0.5)',
    dropdown: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  },
  dropShadow: {
    dropdown: '0px 2px 4px rgba(0, 0, 0, 0.15)',
  },
  gridTemplateColumns: {
    'action-buttons': 'repeat(auto-fill,minmax(200px, 1fr));',
  },
  backgroundImage: {
    'top-gradient': 'linear-gradient(to bottom, #98989880 0px, #d9d9d900 7px)',
    'bottom-gradient': 'linear-gradient(to top, #98989880 0px, #d9d9d900 7px)',
    'combined-gradient':
      'linear-gradient(to bottom, #98989880 0px, #d9d9d900 7px), linear-gradient(to top, #98989880 0px, #d9d9d900 7px)',
  },
}

export default {
  content: ['./index.html', './src/**/*.{js,ts,jsx,tsx}'],
  theme: {
    extend: { ...theme },
  },
  plugins: [],
  corePlugins: {
    preflight: false,
  },
} satisfies Config
