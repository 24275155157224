import {
  ChartAxis,
  ChartType,
  SeriesId,
  SeriesOptions,
} from 'src/types/chartTypes'
import { Checkbox, Icon, Text, TableConfig } from 'src/components/ui'
import { light } from '@fortawesome/fontawesome-svg-core/import.macro'

interface Actions {
  chartType: ChartType
  addSeries: (data: SeriesOptions, position?: ChartAxis) => void
  removeSeries: (id: SeriesId) => void
}

export const getAnomaliesConfig = ({
  chartType,
  addSeries,
  removeSeries,
}: Actions): TableConfig => {
  let selectionColums: any[] = []
  if (chartType === ChartType.TimeSeries) {
    selectionColums = [
      {
        field: 'isSelected',
        headerName: '',
        width: 50,
        minWidth: 50,
        menuTabs: [],
        cellRenderer: ({ data: { modelId, type, isSelected } }: any) => {
          const series = { id: modelId, type }
          return (
            <Checkbox
              value={isSelected}
              onChange={val => {
                if (val) addSeries(series)
                else removeSeries(series)
              }}
            />
          )
        },
        // colAllowFiltering: false,
        sortable: false,
      },
    ]
  } else {
    selectionColums = [
      {
        headerName: 'x',
        field: 'selectedX',
        headerComponent: () => <Icon icon={light('square-x')} size="big" />,
        width: 60,
        minWidth: 60,
        menuTabs: [],
        cellRenderer: ({
          data: { modelId, type, selectedX },
        }: {
          data: {
            modelId: string
            type: any
            selectedX?: boolean
          }
        }) => {
          const series = { id: modelId, type }
          return (
            <div
              className="flex aspect-square w-[14px] items-center justify-center rounded-full border border-solid border-black"
              onClick={() => addSeries(series, ChartAxis.X)}
            >
              {selectedX && (
                <div className="aspect-square w-[7px] rounded-full bg-green-primary" />
              )}
            </div>
          )
        },
      },
      {
        headerName: 'y',
        field: 'selectedY',
        headerComponent: () => <Icon icon={light('square-y')} size="big" />,
        width: 60,
        minWidth: 60,
        menuTabs: [],
        cellRenderer: ({
          data: { modelId, type, selectedY },
        }: {
          data: {
            modelId: string
            type: any
            selectedY?: boolean
          }
        }) => {
          const series = { id: modelId, type }
          return (
            <div
              className="flex aspect-square w-[14px] items-center justify-center rounded-full border border-solid border-black"
              onClick={() => addSeries(series, ChartAxis.Y)}
            >
              {selectedY && (
                <div className="aspect-square w-[7px] rounded-full bg-green-primary" />
              )}
            </div>
          )
        },
      },
    ]
  }
  return {
    pageSize: 25,
    allowPaging: true,
    allowSorting: true,
    pageSizes: [5, 10, 25, 100],
    disallowSelection: true,
    columns: [
      ...selectionColums,
      {
        field: 'name',
        headerName: 'Name',
        flex: 200,
        cellRenderer: ({ value }: any) => {
          return <Text>{value}</Text>
        },
      },
      {
        field: 'tag.displayTagName',
        headerName: 'Tag Name',
        flex: 200,
        cellRenderer: ({
          data: {
            tag: { displayTagName, tagName },
          },
        }: any) => (
          <div title={tagName}>
            <Text>{displayTagName}</Text>
          </div>
        ),
      },
      {
        field: 'label',
        headerName: 'Data Type',
        flex: 250,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
    ],
  }
}
