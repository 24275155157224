import { light } from '@fortawesome/fontawesome-svg-core/import.macro'
import {
  Checkbox,
  Icon,
  Text,
  TableConfig,
  getBaseFilterParams,
} from 'src/components/ui'
import {
  ChartAxis,
  ChartType,
  SeriesId,
  SeriesOptions,
} from 'src/types/chartTypes'

export interface TableTag {
  // the tag name
  id: string
  description: string
  engUnit: string
  displayTagName: string
}

interface AddDataActions {
  chartType: ChartType
  addSeries: (data: SeriesOptions, position?: ChartAxis) => void
  removeSeries: (id: SeriesId) => void
}

export const getTagsConfig = ({
  chartType,
  addSeries,
  removeSeries,
}: AddDataActions): TableConfig<TableTag> => {
  let selectionColums: any[] = []
  if (chartType === ChartType.TimeSeries) {
    selectionColums = [
      {
        headerName: '',
        field: 'isSelected',
        width: 50,
        minWidth: 50,
        menuTabs: [],
        cellRenderer: ({ data: { id, isSelected } }: any) => {
          const series: SeriesId = { type: 'tag', id }
          return (
            <Checkbox
              value={isSelected}
              onChange={val => {
                if (val) addSeries(series)
                else removeSeries(series)
              }}
            />
          )
        },
        // colAllowFiltering: false,
        sortable: false,
      },
    ]
  } else {
    selectionColums = [
      {
        headerName: 'X',
        field: 'selectedX',
        headerComponent: () => <Icon icon={light('square-x')} size="big" />,
        menuTabs: [],
        width: 60,
        minWidth: 60,
        cellRenderer: ({
          data: { id, selectedAxis },
        }: {
          data: {
            id: string
            selectedAxis?: ChartAxis
          }
        }) => {
          const series: SeriesId = { type: 'tag', id }
          return (
            <div
              className="flex aspect-square w-[14px] items-center justify-center rounded-full border border-solid border-black"
              onClick={() => addSeries(series, ChartAxis.X)}
            >
              {selectedAxis === ChartAxis.X && (
                <div className="aspect-square w-[7px] rounded-full bg-green-primary" />
              )}
            </div>
          )
        },
      },
      {
        headerName: 'Y',
        field: 'selectedY',
        headerComponent: () => <Icon icon={light('square-y')} size="big" />,
        width: 60,
        minWidth: 60,
        menuTabs: [],
        cellRenderer: ({
          data: { id, selectedAxis },
        }: {
          data: {
            id: string
            selectedAxis?: ChartAxis
          }
        }) => {
          const series: SeriesId = { type: 'tag', id }
          return (
            <div
              className="flex aspect-square w-[14px] items-center justify-center rounded-full border border-solid border-black"
              onClick={() => addSeries(series, ChartAxis.Y)}
            >
              {selectedAxis === ChartAxis.Y && (
                <div className="aspect-square w-[7px] rounded-full bg-green-primary" />
              )}
            </div>
          )
        },
      },
    ]
  }
  return {
    pageSize: 25,
    pageSizes: [10, 25, 50, 100, 200],
    allowPaging: true,
    allowSorting: true,
    disallowSelection: true,
    columns: [
      ...selectionColums,
      {
        field: 'displayTagName',
        headerName: 'Name',
        flex: 150,
        filterParams: getBaseFilterParams(true),
        cellRenderer: ({ data: { displayTagName, id } }: any) => (
          <div title={id}>
            <Text>{displayTagName}</Text>
          </div>
        ),
      },
      {
        field: 'description',
        headerName: 'Description',
        flex: 150,
        filterParams: getBaseFilterParams(true),
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
      {
        field: 'engUnit',
        headerName: 'Unit',
        flex: 120,
        cellRenderer: ({ value }: any) => <Text>{value}</Text>,
      },
    ],
  }
}
