import React from 'react'
import {
  anomalyDisplayTimeRange,
  anomalyDuration,
  OnlyModelProps,
} from 'src/types'
import useTimeRange from 'src/contexts/timeRange'
import { DynamicTable, Button, Card, Text } from 'src/components/ui'
import { useSite } from 'src/contexts/site'
import { useNavigate } from 'react-router-dom'
import { add, sub } from 'date-fns'
import { TableAnomaly } from 'anomalies/anomaly.types'
import { getAnomaliesModelConfig } from './modelAnomaliesTable.config'
import { useModelAnomalies } from './anomalies.api'

export function Anomalies({ model }: OnlyModelProps): JSX.Element {
  const { data: anomalies = [] } = useModelAnomalies(model.id)
  const [isOpen, setIsOpen] = React.useState(false)
  const { rootLink } = useSite()
  const navigate = useNavigate()
  const { setTimeRange } = useTimeRange()

  const anomalyCount = model.activeTrainedModel?.anomalyCount ?? 0

  const goToAnomaly = (id: string): void =>
    navigate(`${rootLink}/anomalies/${id}`)

  const actions = {
    renderAction: ({ data: anomaly }: any) => (
      <div className="grid grid-flow-col items-center justify-end gap-[1em]">
        <Button
          title="Go to anomaly page"
          onClick={() => goToAnomaly(anomaly.id)}
          variant="secondary"
        />
        <Button
          title="Select time range"
          variant="primary"
          onClick={() => {
            const { from, to } = anomalyDisplayTimeRange(anomaly)
            setTimeRange({
              from: sub(from, { minutes: 30 }).valueOf(),
              to: add(to, { minutes: 30 }).valueOf(),
            })
          }}
        />
      </div>
    ),
  }

  // add fields required by the table
  const tableAnomalies = anomalies.map(a => {
    const ta: TableAnomaly = {
      commentsCount: a.comments.length,
      duration: anomalyDuration(a),
      modelId: model.id,
      ...a,
    }
    return ta
  })

  return (
    <Card className="col-span-2 flex flex-col gap-[1em]">
      <div className="flex items-center gap-[0.5em]">
        <Text variant="title" bold>
          Anomalies ({anomalyCount})
        </Text>
        <Button
          variant="tertiary"
          onClick={() => setIsOpen(isOpen => !isOpen)}
          title={`${isOpen ? 'Hide' : 'Show'}`}
        />
      </div>
      {isOpen && (
        <>
          <DynamicTable
            id="ModelAnomalies"
            data={tableAnomalies}
            config={getAnomaliesModelConfig(actions)}
            className="!h-[350px]"
          />
        </>
      )}
    </Card>
  )
}
