import React from 'react'
import { useNavigate } from 'react-router-dom'
import { SelectInput, Text, Modal } from 'src/components/ui'
import { GqlGatewayFragment } from 'src/services'
import { OpcConnectionSelector } from './OpcConnectionSelector'

const DataSourceTypes = [
  {
    label: 'OPC-UA Connection',
    value: 'opcua',
  },
  {
    label: 'File Ingest',
    value: 'file',
  },
]

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  handleSubmit: () => void
  gateways: GqlGatewayFragment[]
}
export function CreateDataSubscriptionModal({
  gateways,
  isOpen,
  setIsOpen,
  handleSubmit,
}: Props): JSX.Element {
  const [selectedGateway, setSelectedGateway] = React.useState('')
  const [selectedDataSourceType, setSelectedDataSourceType] = React.useState('')
  const [selectedConnection, setSelectedConnection] = React.useState('')
  const navigate = useNavigate()
  const gatewayOptions = gateways.map(gateway => ({
    label: gateway.hostname,
    value: gateway.id,
  }))

  function handleCreateConnection(): void {
    navigate(
      window.location.pathname.substring(
        0,
        window.location.pathname.lastIndexOf('/'),
      ) + `/gateways/${selectedGateway}/opc-ua/configuration`,
    )
  }

  return (
    <Modal
      allowOverflow
      isOpen={isOpen}
      title="Create Data Subscription"
      close={() => setIsOpen(false)}
      footer={
        <Modal.Footer
          onCancel={() => setIsOpen(false)}
          onConfirm={handleSubmit}
          confirmLabel="Create Subscription"
          type="success"
          buttonDisabled={
            !selectedDataSourceType ||
            !selectedGateway ||
            (selectedDataSourceType === 'opcua' && !selectedConnection)
          }
        />
      }
    >
      <div className="flex max-w-[350px] flex-col gap-s py-s">
        <Text variant="description">
          Set up a new subscription, configuring and integrating data for
          optimal operation.
        </Text>
        <div className="flex flex-col gap-2xs">
          <Text variant="description" bold>
            Select Gateway
          </Text>
          <SelectInput
            bold
            value={gatewayOptions.find(
              gateway => gateway.value === selectedGateway,
            )}
            placeholder="Select Gateway"
            textVariant="description"
            options={gatewayOptions}
            onChange={value => {
              setSelectedGateway(value)
            }}
          />
        </div>
        <div className="flex flex-col gap-2xs">
          <Text variant="description" bold>
            Select Data Source Type
          </Text>
          <SelectInput
            bold
            placeholder="Select Data Source Type"
            textVariant="description"
            value={DataSourceTypes.find(
              dataSourceType => dataSourceType.value === selectedDataSourceType,
            )}
            options={DataSourceTypes}
            onChange={value => setSelectedDataSourceType(value)}
          />
        </div>
        {selectedDataSourceType === 'opcua' && (
          <OpcConnectionSelector
            selectedConnection={selectedConnection}
            setSelectedConnection={setSelectedConnection}
            gatewayId={selectedGateway}
            handleCreateConnection={handleCreateConnection}
          />
        )}
      </div>
    </Modal>
  )
}
