import { useGateways } from 'gateway/api'
import { DynamicTable, Text } from 'src/components/ui'
import { useNavigate, useParams } from 'react-router-dom'
import { useFlags } from 'launchdarkly-react-client-sdk'
// TODO: This will get moved
import { gatewaysTableConfig } from 'src/components/ui/tables/DynamicTable'

export function SiteGateways(): JSX.Element {
  const { orgId, siteId } = useParams()
  if (!siteId || !orgId) throw new Error('route param missing')
  const { data } = useGateways(siteId)
  const { gatewayDetails } = useFlags()
  const navigate = useNavigate()

  function handleRowClick(row: any): void {
    if (!gatewayDetails) return
    navigate(`/settings/orgs/${orgId}/sites/${siteId}/gateways/${row.id}`)
  }
  return (
    <div className="flex flex-1 flex-col">
      <div className="my-m">
        <Text variant="description" className="my-xs">
          All of the Intelecy gateways installed at this site.
        </Text>
      </div>
      <DynamicTable
        actions={{
          selectRow: handleRowClick,
        }}
        id="Gateways"
        data={data || []}
        config={gatewaysTableConfig(orgId, siteId, gatewayDetails)}
      />
    </div>
  )
}
